import { useMemo } from 'react';
import { Button, Card, Grid, Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import CardButtonGroup from './CardButtonGroup';


const CardWithButtonGroup = ({
    title,
    isCreationForm,
    isBeingEdited,
    isLoading,
    onEdit,
    onCancelEditing,
    onSave,
    onDelete,
    additionalButtons = [],
    additionalButtonsWhileEditing = [],
    additionalButtonsWhileNotEditing = [],
    children,
}) => {
    const viewportSize = Grid.useBreakpoint();

    const shouldShowButtonGroupOnlyViaPopover = useMemo(() => (
        !isCreationForm && !isBeingEdited && !viewportSize.lg
    ), [
        isCreationForm,
        isBeingEdited,
        viewportSize,
    ]);

    const cardButtonGroup = useMemo(() => (
        <CardButtonGroup
            isCreationForm={isCreationForm}
            isBeingEdited={isBeingEdited}
            isLoading={isLoading}
            onSave={onSave}
            onEdit={onEdit}
            onCancelEditing={onCancelEditing}
            onDelete={onDelete}
            additionalButtons={additionalButtons}
            additionalButtonsWhileEditing={additionalButtonsWhileEditing}
            additionalButtonsWhileNotEditing={additionalButtonsWhileNotEditing}
            style={shouldShowButtonGroupOnlyViaPopover && {
                marginTop: 0,
                minWidth: '60vw',
            }}
        />
    ), [
        isCreationForm,
        isBeingEdited,
        isLoading,
        onSave,
        onEdit,
        onCancelEditing,
        onDelete,
        additionalButtons,
        additionalButtonsWhileEditing,
        additionalButtonsWhileNotEditing,
        shouldShowButtonGroupOnlyViaPopover,
    ]);


    return (
        <Card title={title}>
            {children}

            {shouldShowButtonGroupOnlyViaPopover ? (
                <Popover
                    placement='bottomRight'
                    trigger='click'
                    content={cardButtonGroup}
                >
                    <Button
                        type='text'
                        icon={<MoreOutlined />}
                        style={{ position: 'absolute', top: 12, right: 12, zIndex: 1 }}
                    />
                </Popover>
            ) : (
                cardButtonGroup
            )}
        </Card>
    );
};


export default CardWithButtonGroup;
