import { Grid } from 'antd';

import { UserRole } from '../../core/utilities/constants';
import { useCurrentQueryParameters } from '../../hooks/useCurrentQueryParameters';
import { useCurrentUserRole } from '../../hooks/useCurrentUserRole';

import TaskLinkPage from './TaskLinkPage';
import TasksAdministrativeCompactPage from './TasksAdministrativeCompactPage';
import TasksAdministrativePage from './TasksAdministrativePage';
import TasksRegularPage from './TasksRegularPage';


const TasksPage = () => {
    const queryParameters = useCurrentQueryParameters();
    const taskCompletionId = queryParameters.get('completion-id');
    const currentUserRole = useCurrentUserRole();
    const viewportSize = Grid.useBreakpoint();

    if (taskCompletionId !== null) { return <TaskLinkPage taskCompletionId={taskCompletionId} />; }

    switch (currentUserRole) {
        case UserRole.director:
        case UserRole.manager:
            return viewportSize.xl ? <TasksAdministrativePage /> : <TasksAdministrativeCompactPage />;
        
        default:
            return <TasksRegularPage />;
    }
};


export default TasksPage;
