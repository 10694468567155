import { matchRoutes, useLocation } from 'react-router-dom';

import { useRoutes } from './useRoutes';


export const useCurrentRoute = () => {
    const routes = useRoutes();
    const location = useLocation();
    const matchedRoutes = matchRoutes(routes, location);

    return (matchedRoutes !== null) ? matchedRoutes[0].route : null;
}
