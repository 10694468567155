import { TaskStatus } from '../../../core/utilities/constants';


export const changeTaskStatusTab = (
    taskStatusTabKey,
    setTaskStatusTabKey,
    setTaskStatus,
) => {
    setTaskStatusTabKey(parseInt(taskStatusTabKey));

    switch (taskStatusTabKey) {
        case 1:
            setTaskStatus(TaskStatus.inProgress);
            break;
        case 2:
            setTaskStatus(TaskStatus.overdue);
            break;
        case 3:
            setTaskStatus(TaskStatus.completed);
            break;

        default:
            break;
    }
};
