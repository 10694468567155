import { useState } from 'react';
import { Col, Divider, Layout, Row, Tabs, Typography } from 'antd';

import * as TaskService from '../../core/services/task';
import { TaskStatus } from '../../core/utilities/constants';

import TaskForEmployee from '../../components/cards/TaskForEmployee';
import TaskFromEmployee from '../../components/cards/TaskFromEmployee';
import BaseLayout from '../../components/layouts/BaseLayout';

import TasksTab from './tabs/TasksTab';

import { changeTaskStatusTab } from './utilities/callables';
import { taskStatusTabs } from './utilities/constants';


const TasksAdministrativePage = () => {
    const [ statusOfTasksForEmployee, setStatusOfTasksForEmployee ] = useState(TaskStatus.inProgress);
    const [ statusOfTasksFromEmployee, setStatusOfTasksFromEmployee ] = useState(TaskStatus.inProgress);
    const [ currentTabKeyOfTasksForEmployee, setCurrentTabKeyOfTasksForEmployee ] = useState(taskStatusTabs[0].key);
    const [ currentTabKeyOfTasksFromEmployee, setCurrentTabKeyOfTasksFromEmployee ] = useState(taskStatusTabs[0].key);


    return (
        <BaseLayout>
            <Row wrap={false} style={{
                maxHeight: '100vh',
                maxWidth: '100vw',
                width: 1440,
                margin: '0 auto',
                overflow: 'hidden',
            }}>
                <Col flex='0 0 49.5%' style={{
                    padding: 16,
                    overflow: 'auto',
                }}>
                    <Typography.Title>
                        Мои задачи
                    </Typography.Title>

                    <Layout.Content>
                        <Tabs
                            activeKey={currentTabKeyOfTasksForEmployee}
                            items={taskStatusTabs}
                            onChange={(tabKey) => {
                                changeTaskStatusTab(
                                    tabKey,
                                    setCurrentTabKeyOfTasksForEmployee,
                                    setStatusOfTasksForEmployee,
                                );
                            }}
                        />

                        <TasksTab
                            taskStatus={statusOfTasksForEmployee}
                            getTaskList={TaskService.getTaskListForEmployee}
                            Task={TaskForEmployee}
                        />
                    </Layout.Content>
                </Col>

                <Col flex='0 0 1%'>
                    <Divider type='vertical' style={{
                        height: '100%',
                        margin: '0 50%',
                    }} />
                </Col>

                <Col flex='0 0 49.5%' style={{
                    padding: 16,
                    overflow: 'auto',
                }}>
                    <Typography.Title>
                        Задачи от меня
                    </Typography.Title>

                    <Layout.Content>
                        <Tabs
                            activeKey={currentTabKeyOfTasksFromEmployee}
                            items={taskStatusTabs}
                            onChange={(tabKey) => {
                                changeTaskStatusTab(
                                    tabKey,
                                    setCurrentTabKeyOfTasksFromEmployee,
                                    setStatusOfTasksFromEmployee,
                                );
                            }}
                        />

                        <TasksTab
                            taskStatus={statusOfTasksFromEmployee}
                            getTaskList={TaskService.getTaskListFromEmployee}
                            Task={TaskFromEmployee}
                        />
                    </Layout.Content>
                </Col>
            </Row>
        </BaseLayout>
    );
};


export default TasksAdministrativePage;
