import { useContext, useState } from 'react';
import { Button, Form, Input, Row, notification } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import * as AuthService from '../core/services/auth';
import { makeRequiredRule } from '../core/utilities/callables';
import { AuthContext } from '../hooks/contexts';


const SignInPage = () => {
    const [ notificator, notificationContextHolder ] = notification.useNotification();
    const { signIn } = useContext(AuthContext);

    const [ emailFieldStatus, setEmailFieldStatus ] = useState(null);
    const [ passwordFieldStatus, setPasswordFieldStatus ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const onFinish = async ({ email, password }) => {
        setIsLoading(true);

        setEmailFieldStatus(null);
        setPasswordFieldStatus(null);

        try {
            const { access_token, refresh_token, user_role } = await AuthService.createTokens(email, password);

            signIn(access_token, refresh_token, user_role);
        } catch (error) {
            notificator.error({
                message: 'Ошибка входа',
                description: error.message,
                placement: 'bottom',
            });

            if (!error.errorData.error_fields) { return; }

            if (error.errorData.error_fields.includes('email')) {
                setEmailFieldStatus('error');
            }
            if (error.errorData.error_fields.includes('password')) {
                setPasswordFieldStatus('error');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {notificationContextHolder}

            <Row justify='center' align='middle' style={{
                height: `calc(${window.innerHeight}px - 32px - env(safe-area-inset-bottom) - env(safe-area-inset-top))`,
            }}>
                <Form name='sign-in-form' onFinish={onFinish}>
                    <Form.Item>
                        <h1 className='text--center'>Чек-лист</h1>
                        <h2 className='text--center'>Складов</h2>
                    </Form.Item>

                    <Form.Item
                        name='email'
                        rules={[ makeRequiredRule('Введите вашу почту') ]}
                    >
                        <Input prefix={<UserOutlined />} type='email' placeholder='Почта' allowClear status={emailFieldStatus} />
                    </Form.Item>

                    <Form.Item
                        name='password'
                        rules={[ makeRequiredRule('Введите ваш пароль') ]}
                    >
                        <Input.Password prefix={<LockOutlined />} placeholder='Пароль' allowClear status={passwordFieldStatus} />
                    </Form.Item>

                    <Form.Item>
                        <Button type='primary' htmlType='submit' loading={isLoading} className='width--full-size'>Войти</Button>
                    </Form.Item>
                </Form>
            </Row>
        </>
    );
};


export default SignInPage;
