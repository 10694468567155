import api from '../api';
import { recordsTotalNumberHeader } from '../utilities/constants';


export const getSectionListWithEntries = async (
    warehouseId,
    page,
    size,
) => {
    const response = await api.get('/section/list/with-entries', {
        params: {
            warehouse_id: warehouseId,
            page,
            size,
        },
    });

    return [
        response.data,
        response.headers[recordsTotalNumberHeader],
    ];
};


export const getSectionList = async (
    page,
    size,
    searchQuery,
) => {
    const response = await api.get('/section/list', {
        params: {
            page,
            size,
            search_query: searchQuery
        },
    });

    return [
        response.data,
        response.headers[recordsTotalNumberHeader],
    ];
};


export const updateSectionEntries = async (
    sectionId,
    warehouseId,
    entriesToCreate,
    entriesToUpdate,
    entryIdsToDelete,
    subsections,
) => {
    const response = await api.patch('/section/entries', {
        entries_to_create: entriesToCreate,
        entries_to_update: entriesToUpdate,
        entry_ids_to_delete: entryIdsToDelete,
        subsections,
    }, {
        params: {
            id: sectionId,
            warehouse_id: warehouseId,
        },
    });

    return response.data;
};


export const createSection = async (
    warehouseIds,
    employeeIds,
    name,
    indicators,
    subsections,
) => {
    const response = await api.post('/section', {
        warehouse_ids: warehouseIds,
        employee_ids: employeeIds,
        name,
        indicators,
        subsections,
    });

    return response.data;
};


export const updateSection = async (
    sectionId,
    warehouseIds,
    employeeIds,
    name,
    indicatorsToCreate,
    indicatorsToUpdate,
    indicatorIdsToDelete,
    subsectionsToCreate,
    subsectionsToUpdate,
    subsectionIdsToDelete,
) => {
    let updateData = {};

    if (warehouseIds !== undefined) { updateData.warehouse_ids = warehouseIds; }
    if (employeeIds !== undefined) { updateData.employee_ids = employeeIds; }
    if (name !== undefined) { updateData.name = name; }
    if (indicatorsToCreate !== undefined) { updateData.indicators_to_create = indicatorsToCreate; }
    if (indicatorsToUpdate !== undefined) { updateData.indicators_to_update = indicatorsToUpdate; }
    if (indicatorIdsToDelete !== undefined) { updateData.indicator_ids_to_delete = indicatorIdsToDelete; }
    if (subsectionsToCreate !== undefined) { updateData.subsections_to_create = subsectionsToCreate; }
    if (subsectionsToUpdate !== undefined) { updateData.subsections_to_update = subsectionsToUpdate; }
    if (subsectionIdsToDelete !== undefined) { updateData.subsection_ids_to_delete = subsectionIdsToDelete; }

    const response = await api.patch('/section', updateData, {
        params: {
            id: sectionId,
        },
    });

    return response.data;
};


export const deleteSection = async (sectionId) => {
    const response = await api.delete('/section', {
        params: {
            id: sectionId,
        },
    });

    return response.data;
};
