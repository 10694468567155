import { useState } from 'react';
import { Alert, Card, Divider, Form, Input, Select, Space, Typography, notification } from 'antd';
import { AppstoreAddOutlined, FolderAddOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';

import * as SectionService from '../../core/services/section';
import { makeRequiredRule } from '../../core/utilities/callables';
import { emptyInputValues } from '../../core/utilities/constants';

import { filterOption, isFormFieldValueChanged, makeFormFieldOptionGroupsSelectable } from '../utilities/callables';

import CardWithButtonGroup from '../CardWithButtonGroup';
import IndicatorFormItem from '../formEntities/IndicatorFormItem';
import SubsectionFormItems from '../formEntities/SubsectionFormItems';


const Section = ({
    title,
    section = null,
    warehouseOptions,
    employeeOptions,
    creationForm: isCreationForm = false,
    onSubmit,
}) => {
    const [ notificator, notificationContextHolder ] = notification.useNotification();
    const [ form ] = Form.useForm();

    const [ isBeingEdited, setIsBeingEdited ] = useState(isCreationForm);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isWarehousesSelectOpen, setIsWarehousesSelectOpen ] = useState(false);
    const [ isEmployeesSelectOpen, setIsEmployeesSelectOpen ] = useState(false);

    const [ indicatorsToUpdate ] = useState(section?.indicators ?? []);
    const [ indicatorsToCreate, setIndicatorsToCreate ] = useState(isCreationForm ? [ { pseudoId: 0 } ] : []);
    const [ indicatorIdsToDelete, setIndicatorIdsToDelete ] = useState([]);
    const [ subsectionsToUpdate ] = useState(section?.subsections ?? []);
    const [ subsectionsToCreate, setSubsectionsToCreate ] = useState(isCreationForm ? [ { pseudoId: 0 } ] : []);
    const [ subsectionIdsToDelete, setSubsectionIdsToDelete ] = useState([]);
    const [ indicatorIdsToDeleteBySubsections, setIndicatorIdsToDeleteBySubsections ] = useState({});

    const onWarehousesSelectOpenChange = (isOpen) => {
        setIsWarehousesSelectOpen(isBeingEdited && isOpen);
    };
    const onEmployeesSelectOpenChange = (isOpen) => {
        setIsEmployeesSelectOpen(isBeingEdited && isOpen);
    };

    const addIndicatorToCreate = () => {
        setIndicatorsToCreate([
            ...indicatorsToCreate,
            {
                pseudoId: (indicatorsToCreate[indicatorsToCreate.length - 1]?.pseudoId ?? -1) + 1,
            },
        ]);
    };
    const removeIndicatorToCreate = (indicatorPseudoId) => {
        setIndicatorsToCreate(indicatorsToCreate.filter((indicator) => indicator.pseudoId !== indicatorPseudoId));
    };

    const addIndicatorToDelete = (indicatorId) => {
        setIndicatorIdsToDelete([
            ...indicatorIdsToDelete,
            indicatorId,
        ]);
    };
    const removeIndicatorToDelete = (markedIndicatorId) => {
        setIndicatorIdsToDelete(indicatorIdsToDelete.filter((indicatorId) => indicatorId !== markedIndicatorId));
    };

    const addSubsectionToCreate = () => {
        setSubsectionsToCreate([
            ...subsectionsToCreate,
            {
                pseudoId: (subsectionsToCreate[subsectionsToCreate.length - 1]?.pseudoId ?? -1) + 1,
            },
        ]);
    };
    const removeSubsectionToCreate = (subsectionPseudoId) => {
        setSubsectionsToCreate(subsectionsToCreate.filter((subsection) => subsection.pseudoId !== subsectionPseudoId));
    };

    const addSubsectionToDelete = (subsectionId) => {
        setSubsectionIdsToDelete([
            ...subsectionIdsToDelete,
            subsectionId,
        ]);
    };
    const removeSubsectionToDelete = (markedSubsectionId) => {
        setSubsectionIdsToDelete(subsectionIdsToDelete.filter((subsectionId) => subsectionId !== markedSubsectionId));
    };

    const editSection = () => {
        if (isCreationForm) { return; }

        setIsBeingEdited(true);
    };
    const cancelEditingSection = () => {
        form.resetFields();

        setIndicatorsToCreate([]);
        setIndicatorIdsToDelete([]);

        setSubsectionsToCreate([]);
        setSubsectionIdsToDelete([]);

        setIndicatorIdsToDeleteBySubsections({});

        setIsBeingEdited(false);
    };
    const saveSection = async () => {
        const formDataEntries = Object.entries(form.getFieldsValue()).filter(([ fieldName, fieldValue ]) => {
            if (fieldName.startsWith('create-') || fieldName.startsWith('indicator-to-update-')) {
                return Object.values(fieldValue).every((indicatorValue) => !emptyInputValues.includes(indicatorValue));
            }

            return isFormFieldValueChanged(section, fieldName, fieldValue, isCreationForm);
        });

        const formData = Object.fromEntries(formDataEntries);

        const indicatorsToUpdateForRequest = indicatorsToUpdate.filter((indicator) => (
            JSON.stringify(indicator) !== JSON.stringify({
                id: indicator.id,
                ...formData[`indicator-to-update-${indicator.id}`],
            })
        )).map((indicator) => ({
            id: indicator.id,
            ...formData[`indicator-to-update-${indicator.id}`],
        }));
        const indicatorsToCreateForRequest = indicatorsToCreate
            .map((indicator) => formData[`indicator-to-create-${indicator.pseudoId}`])
            .filter((indicator) => indicator.name || indicator.data_type);

        const subsectionsToUpdateForRequest = subsectionsToUpdate.map((subsection) => {
            const subsectionIndicatorsToUpdate = subsection.indicators.map((indicator) => ({
                id: indicator.id,
                ...formData[`subsection-to-update-${subsection.id}`][`indicator-to-update-${indicator.id}`],
            }));
            const subsectionIndicatorsToCreate = Object
                .entries(formData[`subsection-to-update-${subsection.id}`])
                .filter(([ key, _ ]) => key.startsWith('indicator-to-create-'))
                .map(([ _, value ]) => value);

            return {
                id: subsection.id,
                name: formData[`subsection-to-update-${subsection.id}`]['name'],
                indicators_to_create: subsectionIndicatorsToCreate,
                indicators_to_update: subsectionIndicatorsToUpdate,
                indicator_ids_to_delete: indicatorIdsToDeleteBySubsections[subsection.id] ?? [],
            };
        });
        const subsectionsToCreateForRequest = subsectionsToCreate
            .map((subsection) => ({
                name: formData[`subsection-to-create-${subsection.pseudoId}`]['name'],
                indicators: Object
                    .entries(formData[`subsection-to-create-${subsection.pseudoId}`])
                    .filter(([ key, _ ]) => key.startsWith('indicator-to-create-'))
                    .map(([ _, value ]) => value)
                    .filter((indicator) => indicator.name || indicator.data_type),
            }))
            .filter((subsection) => subsection.name);

        if (
            formDataEntries.length === 0 &&
            indicatorsToUpdateForRequest.length === 0 &&
            indicatorsToCreateForRequest.length === 0 &&
            indicatorIdsToDelete.length === 0 &&
            subsectionsToUpdateForRequest.length === 0 &&
            subsectionsToCreateForRequest.length === 0 &&
            subsectionIdsToDelete.length === 0
        ) {
            setIndicatorsToCreate([]);
            setIndicatorIdsToDelete([]);

            setSubsectionsToCreate([]);
            setSubsectionIdsToDelete([]);

            setIndicatorIdsToDeleteBySubsections({});

            if (!isCreationForm) {
                setIsBeingEdited(false);
            }

            return;
        }

        try {
            await form.validateFields();
        } catch {
            return;
        }

        setIsLoading(true);

        try {
            if (isCreationForm) {
                await SectionService.createSection(
                    formData.warehouse_ids,
                    formData.employee_ids,
                    formData.name,
                    indicatorsToCreateForRequest,
                    subsectionsToCreateForRequest,
                );
            } else {
                await SectionService.updateSection(
                    section.id,
                    formData.warehouse_ids,
                    formData.employee_ids,
                    formData.name,
                    indicatorsToCreateForRequest,
                    indicatorsToUpdateForRequest,
                    indicatorIdsToDelete,
                    subsectionsToCreateForRequest,
                    subsectionsToUpdateForRequest,
                    subsectionIdsToDelete,
                );

                setIsBeingEdited(false);
            }

            onSubmit();
        } catch (error) {
            notificator.error({
                message: `Ошибка ${isCreationForm ? 'создания' : 'обновления'} раздела`,
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const deleteSection = async () => {
        setIsLoading(true);

        try {
            await SectionService.deleteSection(section.id);

            onSubmit();
        } catch (error) {
            notificator.error({
                message: 'Ошибка удаления раздела',
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            {notificationContextHolder}

            <CardWithButtonGroup
                title={title}
                isCreationForm={isCreationForm}
                isBeingEdited={isBeingEdited}
                isLoading={isLoading}
                onEdit={editSection}
                onCancelEditing={cancelEditingSection}
                onSave={saveSection}
                onDelete={deleteSection}
                additionalButtons={[
                    {
                        icon: <AppstoreAddOutlined />,
                        onClick: () => {
                            editSection();
                            addIndicatorToCreate();
                        },
                        title: 'Добавить показатель',
                    },
                    {
                        icon: <FolderAddOutlined />,
                        onClick: () => {
                            editSection();
                            addSubsectionToCreate();
                        },
                        title: 'Добавить группу показателей',
                    },
                ]}
            >
                <Form layout='vertical' form={form} initialValues={{ ...section }}>
                    <Form.Item
                        name='name'
                        label='Название'
                        rules={[ makeRequiredRule('Введите название') ]}
                    >
                        <Input readOnly={!isBeingEdited} placeholder='...' />
                    </Form.Item>

                    <Space direction='vertical' className='width--full-size'>
                        <Typography.Text>
                            Исполнители
                        </Typography.Text>

                        <Card type='inner' size='small'>
                            <Form.Item
                                name='warehouse_ids'
                                label={<Space><ShopOutlined /> Склады</Space>}
                            >
                                <Select
                                    virtual={false}
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    removeIcon={null}
                                    showSearch={isBeingEdited}
                                    placeholder='...'
                                    optionFilterProp='children'
                                    filterOption={filterOption}
                                    options={makeFormFieldOptionGroupsSelectable(warehouseOptions, 'warehouse_ids', form)}
                                    open={isWarehousesSelectOpen}
                                    onDropdownVisibleChange={onWarehousesSelectOpenChange}
                                />
                            </Form.Item>

                            <Form.Item
                                name='employee_ids'
                                label={<Space><UserOutlined /> Сотрудники</Space>}
                                style={{ marginBottom: 0 }}
                            >
                                <Select
                                    virtual={false}
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    removeIcon={null}
                                    showSearch={isBeingEdited}
                                    placeholder='...'
                                    optionFilterProp='children'
                                    filterOption={filterOption}
                                    options={makeFormFieldOptionGroupsSelectable(employeeOptions, 'employee_ids', form)}
                                    open={isEmployeesSelectOpen}
                                    onDropdownVisibleChange={onEmployeesSelectOpenChange}
                                />
                            </Form.Item>
                        </Card>
                    </Space>

                    <Typography.Title level={5} style={{ marginTop: 32, marginBottom: 12 }}>
                        Показатели
                    </Typography.Title>

                    {indicatorsToUpdate.length === 0 && indicatorsToCreate.length === 0 ? (
                        <Alert message='Показатели не добавлены' type='info' showIcon />
                    ) : (
                        <>
                            {indicatorsToUpdate.map((indicator) => (
                                <IndicatorFormItem
                                    key={indicator.id}
                                    name={`indicator-to-update-${indicator.id}`}
                                    indicator={indicator}
                                    isBeingEdited={isBeingEdited}
                                    isCreationForm={isCreationForm}
                                    replaceDeleteByRestore={indicatorIdsToDelete.indexOf(indicator.id) !== -1}
                                    onDelete={() => { addIndicatorToDelete(indicator.id); }}
                                    onRestore={() => { removeIndicatorToDelete(indicator.id); }}
                                />
                            ))}

                            {indicatorsToUpdate.length > 0 && indicatorsToCreate.length > 0 && <Divider />}

                            {indicatorsToCreate.map((indicator) => (
                                <IndicatorFormItem
                                    key={indicator.pseudoId}
                                    name={`indicator-to-create-${indicator.pseudoId}`}
                                    isCreationForm={isCreationForm}
                                    onDelete={() => { removeIndicatorToCreate(indicator.pseudoId); }}
                                    hideDelete={isCreationForm && indicatorsToCreate.length <= 1}
                                />
                            ))}
                        </>
                    )}

                    <Typography.Title level={5} style={{ marginTop: 32, marginBottom: 12 }}>
                        Группы показателей
                    </Typography.Title>

                    {subsectionsToUpdate.length === 0 && subsectionsToCreate.length === 0 ? (
                        <Alert message='Группы показателей не добавлены' type='info' showIcon />
                    ) : (
                        <>
                            {subsectionsToUpdate.map((subsection) => (
                                
                                <SubsectionFormItems
                                    key={subsection.id}
                                    name={`subsection-to-update-${subsection.id}`}
                                    subsection={subsection}
                                    isBeingEdited={isBeingEdited}
                                    replaceDeleteByRestore={subsectionIdsToDelete.indexOf(subsection.id) !== -1}
                                    onDelete={() => { addSubsectionToDelete(subsection.id); }}
                                    onRestore={() => { removeSubsectionToDelete(subsection.id); }}
                                    onChangeIndicatorIdsToDelete={(subsectionIndicatorIdsToDelete) => {
                                        setIndicatorIdsToDeleteBySubsections({
                                            ...indicatorIdsToDeleteBySubsections,
                                            [subsection.id]: subsectionIndicatorIdsToDelete,
                                        });
                                    }}
                                />
                            ))}

                            {subsectionsToUpdate.length > 0 && subsectionsToCreate.length > 0 && <Divider />}

                            {subsectionsToCreate.map((subsection) => (
                                <SubsectionFormItems
                                    key={subsection.pseudoId}
                                    name={`subsection-to-create-${subsection.pseudoId}`}
                                    isCreationForm={isCreationForm}
                                    isBeingEdited={isBeingEdited}
                                    onDelete={() => { removeSubsectionToCreate(subsection.pseudoId); }}
                                    hideDelete={isCreationForm && subsectionsToCreate.length <= 1}
                                />
                            ))}
                        </>
                    )}
                </Form>
            </CardWithButtonGroup>
        </>
    );
};


export default Section;
