import { Tooltip } from 'antd';


/**
 * Filter out `undefined` values and values that are same with the initial ones on non-creational form.
 * 
 * @param {any} record 
 * @param {string} fieldName 
 * @param {any} fieldValue 
 * @param {boolean} isCreationForm 
 * @returns {boolean}
 */
export const isFormFieldValueChanged = (record, fieldName, fieldValue, isCreationForm) => {
    return fieldValue !== undefined && (
        isCreationForm || JSON.stringify(fieldValue) !== JSON.stringify(record?.[fieldName])
    );
};


/**
 * Make option groups used in `Select` Ant Design Component selectable.
 * Such selection adds all options of the group to the form field value, even if there are already `some` selected elements.
 * Such selection also removes all options of the group from the form field value if `all` options of the group already selected.
 * 
 * @param {object} optionGroups 
 * @param {string} formFieldName 
 * @param {Form} form 
 * @returns {object} transformed option groups.
 */
export const makeFormFieldOptionGroupsSelectable = (optionGroups, formFieldName, form) => {
    let selectableOptionGroups = optionGroups.map((optionGroup) => ({
        label: (
            <Tooltip
                title='Нажмите, чтобы добавить или исключить всю группу'
                placement='topLeft'
            >
                <div
                    onClick={() => { updateFormFieldValueUsingSingleOptionGroup(optionGroup, formFieldName, form); }}
                    style={{ cursor: 'pointer' }}
                >
                    {optionGroup.label}
                </div>
            </Tooltip>
        ),
        options: optionGroup.options,
    }));

    selectableOptionGroups.unshift({
        label: (
            <Tooltip
                title='Нажмите, чтобы добавить или исключить все элементы'
                placement='topLeft'
            >
                <div
                    onClick={() => { updateFormFieldValueUsingAllOptionGroups(optionGroups, formFieldName, form); }}
                    style={{ cursor: 'pointer' }}
                >
                    Все
                </div>
            </Tooltip>
        ),
        options: [],
    });

    return selectableOptionGroups;
};


/**
 * 
 * @param {string} searchQuery
 * @param {object} optionOrOptionGroup
 * @returns {boolean} whether `input` matches `option` or not.
 */
export const filterOption = (searchQuery, optionOrOptionGroup) => {
    if (!optionOrOptionGroup?.label) { return false; }

    let optionLabel;

    if (typeof optionOrOptionGroup.label === 'object') {
        optionLabel = optionOrOptionGroup.label.props.children.props.children;
    } else {
        optionLabel = optionOrOptionGroup.label;
    }

    return optionLabel.toLowerCase().includes(searchQuery);
}


const updateFormFieldValueUsingSingleOptionGroup = (optionGroup, formFieldName, form) => {
    const formFieldValue = form.getFieldValue(formFieldName) || [];
    const optionGroupValues = optionGroup.options.map((option) => option.value);

    const selectedOptionGroupValues = optionGroupValues.filter((optionValue) => formFieldValue.includes(optionValue));

    if (selectedOptionGroupValues.length === optionGroupValues.length) {
        form.setFieldValue(
            formFieldName,
            formFieldValue.filter((selectedOptionValue) => !optionGroupValues.includes(selectedOptionValue)),
        );
    } else {
        form.setFieldValue(
            formFieldName,
            [ ...new Set([ ...formFieldValue ,...optionGroupValues ]) ],
        );
    }
};


const updateFormFieldValueUsingAllOptionGroups = (optionGroups, formFieldName, form) => {
    const formFieldValue = form.getFieldValue(formFieldName) || [];
    const allOptionGroupValues = optionGroups.reduce(
        (optionValues, optionGroup) => optionValues.concat(optionGroup.options.map((option) => option.value)),
        [],
    );

    if (formFieldValue.length === allOptionGroupValues.length) {
        form.setFieldValue(formFieldName, []);
    } else {
        form.setFieldValue(formFieldName, allOptionGroupValues);
    }
};
