import { ControlOutlined, FormOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { UserRole } from '../core/utilities/constants';

import TitledLayout from '../components/layouts/TitledLayout';

import ControlPage from '../pages/Control';
import EntriesPage from '../pages/Entries';
import SignInPage from '../pages/SignIn';
import TasksPage from '../pages/Tasks';


export const publicRoutes = [
    { path: '/sign-in', page: <SignInPage /> },
];

export const privateRoutes = [
    {
        path: '/tasks',
        title: 'Задачи',
        icon: <UnorderedListOutlined />,
        page: <TasksPage />,
    },
    {
        path: '/entries',
        title: 'Записи показателей',
        icon: <FormOutlined />,
        get page() {
            return (
                <TitledLayout title={this.title}>
                    <EntriesPage />
                </TitledLayout>
            );
        },
    },
    {
        path: '/control',
        title: 'Панель управления',
        icon: <ControlOutlined />,
        allowedRoles: [
            UserRole.director,
            UserRole.manager,
        ],
        get page() {
            return (
                <TitledLayout title={this.title}>
                    <ControlPage />
                </TitledLayout>
            );
        },
    },
];
