import { useEffect, useState } from 'react';
import { Alert, Button, Card, Divider, Dropdown, Form, Grid, Space, notification } from 'antd';
import { AppstoreAddOutlined, EditOutlined, SaveFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

import * as AttachmentService from '../../core/services/attachment';
import * as SectionService from '../../core/services/section';
import { IndicatorDataType, attachmentNamePattern, emptyInputValues, shareDrivePathOrAnyHttpLinkPattern } from '../../core/utilities/constants';

import ButtonGroup from '../ButtonGroup';
import EntryFormItem from '../formEntities/EntryFormItem';
import SubsectionWithEntriesFormItems from '../formEntities/SubsectionWithEntriesFormItems';


const formatEntryValueForOutput = (
    entryDataType,
    entryRawValue,
    entryRawValueAddition = null,
) => {
    switch (entryDataType) {
        case IndicatorDataType.boolean.value:
            return entryRawValue.toString().toLowerCase();
        case IndicatorDataType.date.value:
            return entryRawValue.format('YYYY-MM-DD');
        case IndicatorDataType.photos.value:
            if (typeof entryRawValue === 'string') {
                entryRawValue = JSON.parse(entryRawValue);
            }

            if (Array.isArray(entryRawValue)) {
                // NOTE: `entryRawValue` here has an array of attachment names & file paths received from API as is,
                // but file paths are always passed via `entryRawValueAddition`,
                // so `entryRawValue` has to contain attachment names only to avoid duplications.
                entryRawValue = entryRawValue
                    .filter((attachment) => (attachment.name ?? attachment).search(attachmentNamePattern) === 0)
                    .map((attachment) => attachment.name ?? attachment);
            } else {
                entryRawValue = entryRawValue?.fileList?.map((fileData) => fileData.name);
            }

            entryRawValueAddition = entryRawValueAddition?.filter((filePath) => !emptyInputValues.includes(filePath));

            entryRawValue = (entryRawValue ?? []).concat(entryRawValueAddition ?? []);

            return entryRawValue.length > 0 ? JSON.stringify(entryRawValue) : null;

        default:
            return entryRawValue;
    }
};

const formatEntryValueForInput = (
    entryDataType,
    entryRawValue,
) => {
    switch (entryDataType) {
        case IndicatorDataType.boolean.value:
            return entryRawValue.toString().toLowerCase() === 'true';
        case IndicatorDataType.date.value:
            return dayjs(entryRawValue);

        default:
            return entryRawValue;
    }
};

const formatEntryFileListForInput = (rawFiles) => {
    if (!rawFiles) { return null; }

    return JSON.parse(rawFiles).filter((photoFileName) => (
        photoFileName?.search(attachmentNamePattern) === 0
    )).map((photoFileName) => ({
        status: 'done',
        name: photoFileName,
        url: AttachmentService.attachmentGetterApiUrl + photoFileName,
    }));
};

const formatEntryFilePathsForInput = (rawFiles) => {
    if (!rawFiles) { return null; }

    return JSON.parse(rawFiles).filter((filePathName) => (
        filePathName?.search(shareDrivePathOrAnyHttpLinkPattern) === 0
    ));
};


const SectionWithEntries = ({
    section,
    warehouseId,
    onSubmit,
}) => {
    const viewportSize = Grid.useBreakpoint();
    const [ notificator, notificationContextHolder ] = notification.useNotification();
    const [ form ] = Form.useForm();

    const [ isBeingEdited, setIsBeingEdited ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const [ indicators, setIndicators ] = useState(section.indicators);
    const [ entriesToUpdate ] = useState(section.entries);
    const [ entriesToCreate, setEntriesToCreate ] = useState([]);
    const [ entryIdsToDelete, setEntryIdsToDelete ] = useState([]);
    const [ entriesToCreateBySubsections, setEntriesToCreateBySubsections ] = useState({});
    const [ entryIdsToDeleteBySubsections, setEntryIdsToDeleteBySubsections ] = useState({});

    const generateNextPseudoId = () => {
        return (entriesToCreate[entriesToCreate.length - 1]?.pseudoId ?? -1) + 1;
    };

    const addAllEntriesToCreate = () => {
        setEntriesToCreate(indicators.map((indicator, indicatorIndex) => ({
            pseudoId: generateNextPseudoId() + indicatorIndex,
            name: indicator.name,
            data_type: indicator.data_type,
        })));
    };
    const addEntryToCreate = (indicator) => {
        setEntriesToCreate([
            ...entriesToCreate,
            {
                pseudoId: generateNextPseudoId(),
                name: indicator.name,
                data_type: indicator.data_type,
            },
        ]);
    };
    const removeEntryToCreate = (entryPseudoId) => {
        setEntriesToCreate(entriesToCreate.filter((entry) => entry.pseudoId !== entryPseudoId));
    };

    const addEntryToDelete = (entryId) => {
        setEntryIdsToDelete([
            ...entryIdsToDelete,
            entryId,
        ]);
    };
    const removeEntryToDelete = (markedEntryId) => {
        setEntryIdsToDelete(entryIdsToDelete.filter((entryId) => entryId !== markedEntryId));
    };

    const editSection = () => {
        setIsBeingEdited(true);
    };
    const saveSection = async () => {
        const formData = form.getFieldsValue();

        let entryIdsToDeleteForRequest = [ ...entryIdsToDelete ];

        const entriesToCreateForRequest = entriesToCreate.filter((entry) => (
            !emptyInputValues.includes(formData[`entry-to-create-${entry.pseudoId}`]['value']) ||
            !emptyInputValues.includes(formData[`entry-to-create-${entry.pseudoId}`]['file-paths'])
        )).map((entry) => ({
            name: entry.name,
            data_type: entry.data_type,
            raw_value: formatEntryValueForOutput(
                entry.data_type,
                formData[`entry-to-create-${entry.pseudoId}`]['value'],
                formData[`entry-to-create-${entry.pseudoId}`]['file-paths'],
            ),
            comment: formData[`entry-to-create-${entry.pseudoId}`]['comment'] || null,
            remark: formData[`entry-to-create-${entry.pseudoId}`]['remark'] || null,
            photos: formatEntryValueForOutput(
                IndicatorDataType.photos.value,
                formData[`entry-to-create-${entry.pseudoId}`]['photos'],
                formData[`entry-to-create-${entry.pseudoId}`]['photo-file-paths'],
            ),
        }));

        const entriesToUpdateForRequest = entriesToUpdate.filter((entry) => (
            (
                (
                    !emptyInputValues.includes(formData[`entry-to-update-${entry.id}`]['value']) ||
                    !emptyInputValues.includes(formData[`entry-to-update-${entry.id}`]['file-paths'])
                ) &&
                formatEntryValueForOutput(
                    entry.data_type,
                    formData[`entry-to-update-${entry.id}`]['value'],
                    formData[`entry-to-update-${entry.id}`]['file-paths'],
                ) !== entry.raw_value
            ) ||
            (formData[`entry-to-update-${entry.id}`]['comment'] ?? null) !== entry.comment ||
            (formData[`entry-to-update-${entry.id}`]['remark'] ?? null) !== entry.remark ||
            formatEntryValueForOutput(
                IndicatorDataType.photos.value,
                formData[`entry-to-update-${entry.id}`]['photos'],
                formData[`entry-to-update-${entry.id}`]['photo-file-paths'],
            ) !== entry.photos
        )).map((entry) => {
            const formattedRawValue = formatEntryValueForOutput(
                entry.data_type,
                formData[`entry-to-update-${entry.id}`]['value'],
                formData[`entry-to-update-${entry.id}`]['file-paths'],
            );

            if (entry.data_type === IndicatorDataType.photos.value && !formattedRawValue) {
                entryIdsToDeleteForRequest.push(entry.id);
            }

            return {
                id: entry.id,
                name: entry.name,
                data_type: entry.data_type,
                raw_value: formattedRawValue,
                comment: formData[`entry-to-update-${entry.id}`]['comment'] || null,
                remark: formData[`entry-to-update-${entry.id}`]['remark'] || null,
                photos: formatEntryValueForOutput(
                    IndicatorDataType.photos.value,
                    formData[`entry-to-update-${entry.id}`]['photos'],
                    formData[`entry-to-update-${entry.id}`]['photo-file-paths'],
                ),
            };
        }).filter((entry) => !entryIdsToDeleteForRequest.includes(entry.id));

        const subsectionsForRequest = section.subsections.map((subsection) => {
            let subsectionsEntryIdsToDeleteForRequest = [ ...(entryIdsToDeleteBySubsections[subsection.id] ?? []) ];

            const subsectionEntriesToUpdateForRequest = subsection.entries.filter((entry) => (
                (
                    (
                        !emptyInputValues.includes(formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['value']) ||
                        !emptyInputValues.includes(formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['file-paths'])
                    ) &&
                    formatEntryValueForOutput(
                        entry.data_type,
                        formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['value'],
                        formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['file-paths'],
                    ) !== entry.raw_value
                ) ||
                (formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['comment'] ?? null) !== entry.comment ||
                (formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['remark'] ?? null) !== entry.remark ||
                formatEntryValueForOutput(
                    IndicatorDataType.photos.value,
                    formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['photos'],
                    formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['photo-file-paths'],
                ) !== entry.photos
            )).map((entry) => {
                const formattedRawValue = formatEntryValueForOutput(
                    entry.data_type,
                    formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['value'],
                    formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['file-paths'],
                );

                if (entry.data_type === IndicatorDataType.photos.value && !formattedRawValue) {
                    subsectionsEntryIdsToDeleteForRequest.push(entry.id);
                }

                return {
                    id: entry.id,
                    name: entry.name,
                    data_type: entry.data_type,
                    raw_value: formattedRawValue,
                    comment: formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['comment'] || null,
                    remark: formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['remark'] || null,
                    photos: formatEntryValueForOutput(
                        IndicatorDataType.photos.value,
                        formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['photos'],
                        formData[`subsection-${subsection.id}`][`entry-to-update-${entry.id}`]['photo-file-paths'],
                    ),
                };
            }).filter((entry) => !subsectionsEntryIdsToDeleteForRequest.includes(entry.id));

            const subsectionEntriesToCreateForRequest = (entriesToCreateBySubsections[subsection.id] ?? []).filter((entry) => (
                !emptyInputValues.includes(formData[`subsection-${subsection.id}`][`entry-to-create-${entry.pseudoId}`]['value']) ||
                !emptyInputValues.includes(formData[`subsection-${subsection.id}`][`entry-to-create-${entry.pseudoId}`]['file-paths'])
            )).map((entry) => ({
                name: entry.name,
                data_type: entry.data_type,
                raw_value: formatEntryValueForOutput(
                    entry.data_type,
                    formData[`subsection-${subsection.id}`][`entry-to-create-${entry.pseudoId}`]['value'],
                    formData[`subsection-${subsection.id}`][`entry-to-create-${entry.pseudoId}`]['file-paths'],
                ),
                comment: formData[`subsection-${subsection.id}`][`entry-to-create-${entry.pseudoId}`]['comment'] || null,
                remark: formData[`subsection-${subsection.id}`][`entry-to-create-${entry.pseudoId}`]['remark'] || null,
                photos: formatEntryValueForOutput(
                    IndicatorDataType.photos.value,
                    formData[`subsection-${subsection.id}`][`entry-to-create-${entry.pseudoId}`]['photos'],
                    formData[`subsection-${subsection.id}`][`entry-to-create-${entry.pseudoId}`]['photo-file-paths'],
                ),
            }));

            return {
                id: subsection.id,
                entries_to_update: subsectionEntriesToUpdateForRequest,
                entries_to_create: subsectionEntriesToCreateForRequest,
                entry_ids_to_delete: subsectionsEntryIdsToDeleteForRequest,
            };
        });

        if (
            entriesToUpdateForRequest.length === 0 &&
            entriesToCreateForRequest.length === 0 &&
            entryIdsToDeleteForRequest.length === 0 &&
            subsectionsForRequest.length === 0
        ) {
            setEntriesToCreate([]);
            setEntryIdsToDelete([]);

            setEntriesToCreateBySubsections({});
            setEntryIdsToDeleteBySubsections({});

            setIsBeingEdited(false);

            return;
        }

        setIsLoading(true);

        try {
            await SectionService.updateSectionEntries(
                section.id,
                warehouseId,
                entriesToCreateForRequest,
                entriesToUpdateForRequest,
                entryIdsToDeleteForRequest,
                subsectionsForRequest,
            );

            onSubmit();
        } catch (error) {
            notificator.error({
                message: 'Ошибка обновления раздела',
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        setIndicators(
            section.indicators.filter((indicator) => (
                !entriesToUpdate.find((entry) => entry.name === indicator.name && entry.data_type === indicator.data_type) &&
                !entriesToCreate.find((entry) => entry.name === indicator.name && entry.data_type === indicator.data_type)
            )),
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ entriesToUpdate, entriesToCreate ]);


    return (
        <>
            {notificationContextHolder}

            <Card title={section.name}>
                <Space direction='vertical' size='middle' className='width--full-size'>
                    <Form layout='vertical' form={form}>
                        {entriesToUpdate.length === 0 && entriesToCreate.length === 0 && section.subsections.length === 0 ? (
                            <Alert message='Записи отсутствуют' type='info' showIcon />
                        ) : (
                            <>
                                {entriesToUpdate.map((entry) => (
                                    <EntryFormItem
                                        key={entry.id}
                                        name={`entry-to-update-${entry.id}`}
                                        label={entry.name}
                                        dataType={entry.data_type}
                                        initialRawValue={formatEntryValueForInput(entry.data_type, entry.raw_value)}
                                        initialFileList={entry.data_type === IndicatorDataType.photos.value && formatEntryFileListForInput(entry.raw_value)}
                                        initialFilePaths={entry.data_type === IndicatorDataType.photos.value && formatEntryFilePathsForInput(entry.raw_value)}
                                        initialComment={entry.comment}
                                        initialRemark={entry.remark}
                                        initialPhotoFileList={formatEntryFileListForInput(entry.photos)}
                                        initialPhotoFilePaths={formatEntryFilePathsForInput(entry.photos)}
                                        createdAt={entry.created_at}
                                        updatedAt={entry.updated_at}
                                        isBeingEdited={isBeingEdited}
                                        replaceDeleteByRestore={entryIdsToDelete.indexOf(entry.id) !== -1}
                                        onDelete={() => { addEntryToDelete(entry.id); }}
                                        onRestore={() => { removeEntryToDelete(entry.id); }}
                                    />
                                ))}

                                {entriesToUpdate.length > 0 && section.subsections.length > 0 && <Divider />}

                                {section.subsections.map((subsection) => (
                                    <SubsectionWithEntriesFormItems
                                        key={subsection.id}
                                        name={`subsection-${subsection.id}`}
                                        subsection={subsection}
                                        isBeingEdited={isBeingEdited}
                                        onEdit={editSection}
                                        onChangeEntriesToCreate={(subsectionEntriesToCreate) => {
                                            setEntriesToCreateBySubsections({
                                                ...entriesToCreateBySubsections,
                                                [subsection.id]: subsectionEntriesToCreate,
                                            });
                                        }}
                                        onChangeEntryIdsToDelete={(subsectionEntryIdsToDelete) => {
                                            setEntryIdsToDeleteBySubsections({
                                                ...entryIdsToDeleteBySubsections,
                                                [subsection.id]: subsectionEntryIdsToDelete,
                                            });
                                        }}
                                    />
                                ))}

                                {(entriesToUpdate.length > 0 || section.subsections.length > 0) && entriesToCreate.length > 0 && <Divider />}

                                {entriesToCreate.map((entry) => (
                                    <EntryFormItem
                                        key={entry.pseudoId}
                                        name={`entry-to-create-${entry.pseudoId}`}
                                        label={entry.name}
                                        dataType={entry.data_type}
                                        onDelete={() => { removeEntryToCreate(entry.pseudoId); }}
                                    />
                                ))}
                            </>
                        )}
                    </Form>
                </Space>

                <ButtonGroup>
                    {(entriesToUpdate.length > 0 || entriesToCreate.length > 0 || section.subsections.length > 0) && (
                        <Button
                            type={isBeingEdited ? 'primary' : 'default'}
                            icon={isBeingEdited ? <SaveFilled /> : <EditOutlined />}
                            loading={isBeingEdited && isLoading}
                            onClick={isBeingEdited ? saveSection : editSection}
                            className='width--full-size text--left'
                        >
                            {isBeingEdited ? 'Сохранить' : 'Изменить'}
                        </Button>
                    )}

                    {indicators.length > 0 && (
                        <Dropdown trigger={['click']} overlayStyle={!viewportSize.lg && { width: 0 }} menu={{
                            items: [ 
                                {
                                    key: 0,
                                    label: (
                                        <div>
                                            Добавить все показатели
                                            <Divider style={{ marginTop: 12, marginBottom: 0 }} />
                                        </div>
                                    ),
                                    onClick: () => {
                                        editSection();
                                        addAllEntriesToCreate();
                                    },
                                },
                                ...indicators.map((indicator) => ({
                                    key: indicator.id,
                                    label: indicator.name,
                                    onClick: () => {
                                        editSection();
                                        addEntryToCreate(indicator);
                                    },
                                })),
                            ],
                        }}>
                            <Button type='primary' icon={<AppstoreAddOutlined />} className='width--full-size text--left'>
                                Добавить запись
                            </Button>
                        </Dropdown>
                    )}
                </ButtonGroup>
            </Card>
        </>
    );
};


export default SectionWithEntries;
