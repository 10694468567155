import api, { apiUrl } from '../api';


export const attachmentGetterApiUrl = `${apiUrl}/attachment?name=`;


export const uploadAttachment = async (attachment) => {
    let formData = new FormData();

    formData.append('attachment', attachment);

    const response = await api.post('/attachment', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
};


export const downloadPhoto = async (attachmentName) => {
    const response = await api.get('/attachment', {
        params: {
            name: attachmentName,
        },
    });

    return response.data;
};
