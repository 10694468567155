import { useContext } from 'react';

import { AuthContext } from './contexts';


export const useCurrentUserRole = () => {
    const { getAuthorizationData } = useContext(AuthContext);
    const { userRole } = getAuthorizationData();

    return userRole;
};
