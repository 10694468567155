import { Grid, Layout, Typography } from 'antd';

import BaseLayout from './BaseLayout';


const TitledLayout = ({
    title,
    children,
}) => {
    const viewportSize = Grid.useBreakpoint();

    const titledLayoutStyle = {
        width: viewportSize.lg ? 992 : null,
        margin: viewportSize.lg ? '0 auto' : null,
    };

    
    return (
        <BaseLayout>
            <Typography.Title className='layout__title' style={titledLayoutStyle}>
                {title}
            </Typography.Title>

            <Layout.Content className='layout__content' style={titledLayoutStyle}>
                {children}
            </Layout.Content>
        </BaseLayout>
    );
};


export default TitledLayout;
