import api from '../api';
import { recordsTotalNumberHeader } from '../utilities/constants';


export const getUserRoleList = async () => {
    const response = await api.get('/user/role/list');

    return response.data;
};


export const getUserList = async (
    page,
    size,
    searchQuery,
) => {
    const response = await api.get('/user/list', {
        params: {
            page,
            size,
            search_query: searchQuery,
        },
    });

    return [
        response.data,
        response.headers[recordsTotalNumberHeader],
    ];
};


export const createUser = async (
    warehouseIds,
    email,
    password,
    fullname,
    position,
    role,
) => {
    const response = await api.post('/user', {
        warehouse_ids: warehouseIds,
        email,
        password,
        fullname,
        position,
        role,
    });

    return response.data;
};


export const updateUser = async (
    userId,
    warehouseIds,
    email,
    password,
    fullname,
    position,
    role,
) => {
    let updateData = {};

    if (warehouseIds !== undefined) { updateData.warehouse_ids = warehouseIds; }
    if (email !== undefined) { updateData.email = email; }
    if (password !== undefined) { updateData.password = password; }
    if (fullname !== undefined) { updateData.fullname = fullname; }
    if (position !== undefined) { updateData.position = position; }
    if (role !== undefined) { updateData.role = role; }

    const response = await api.patch('/user', updateData, {
        params: {
            id: userId,
        },
    });

    return response.data;
};


export const deleteUser = async (userId) => {
    const response = await api.delete('/user', {
        params: {
            id: userId,
        },
    });

    return response.data;
};
