import { Card, Descriptions, Grid, Space, Tag, Timeline, Upload } from 'antd';
import { ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import * as AttachmentService from '../../core/services/attachment';
import { capitalizeString, formatWordOfNumber } from '../../core/utilities/callables';


const TaskFromEmployee = ({
    task,
}) => {
    const viewportSize = Grid.useBreakpoint();

    return (
        <>
            <Card title={task.name}>
                <Space direction='vertical' size='middle' className='width--full-size'>
                    <Descriptions layout={viewportSize.lg ? 'vertical' : 'horizontal'} size='small'>
                        <Descriptions.Item label='Срок'>
                            {dayjs(task.deadline).format('DD.MM.YYYY')}
                        </Descriptions.Item>

                        <Descriptions.Item label='Приоритет'>
                            <Tag
                                icon={{
                                    'обычная': null,
                                    'срочная': <ClockCircleOutlined />,
                                    'критическая': <ExclamationCircleOutlined />
                                }[task.priority]}
                                color={{
                                    'обычная': 'default',
                                    'срочная': 'warning',
                                    'критическая': 'error',
                                }[task.priority]}
                            >
                                {capitalizeString(task.priority)}
                            </Tag>
                        </Descriptions.Item>

                        <Descriptions.Item label='Видна исполнителям'>
                            за {task.visibility_days} {formatWordOfNumber(task.visibility_days, 'день', 'дня', 'дней')} до срока
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions layout='vertical' size='small'>
                        <Descriptions.Item
                            label='Исполнители'
                            style={{ paddingRight: 12 }}
                        >
                            {task.assignees_data && task.assignees_data.length > 0 ? (
                                <Timeline
                                    items={task.assignees_data.map((assigneeData) => ({
                                        children: (<>
                                            <p>{assigneeData.employee || `${assigneeData.warehouse} (${assigneeData.warehouse_city})`}</p>
                                            {assigneeData.completed_at && (
                                                <p>
                                                    <span className='hint--block'>завершено</span>
                                                    {dayjs(assigneeData.completed_at).format('DD.MM.YYYY в HH:mm:ss')}
                                                </p>
                                            )}
                                        </>),
                                    }))}
                                    style={{ paddingTop: 8 }}
                                />
                            ) : (
                                'Не назначены'
                            )}
                        </Descriptions.Item>

                        {task.attachments.length !== 0 && (
                            <Descriptions.Item
                                label='Файлы'
                                span={2}
                                style={{ overflow: 'hidden' }}
                            >
                                <Upload
                                    fileList={task.attachments.map((attachmentName) => ({
                                        status: 'done',
                                        name: attachmentName,
                                        url: AttachmentService.attachmentGetterApiUrl + attachmentName,
                                    }))}
                                    showUploadList={{ showRemoveIcon: false }}
                                />
                            </Descriptions.Item>
                        )}
                    </Descriptions>

                    {task.description !== null && (
                        <Descriptions layout='vertical' size='small'>
                            <Descriptions.Item label='Описание' span={3}>
                                {task.description}
                            </Descriptions.Item>
                        </Descriptions>
                    )}
                </Space>
            </Card>
        </>
    );
};


export default TaskFromEmployee;
