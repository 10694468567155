export const recordsTotalNumberHeader = 'x-records-total-number';
export const recordsPageHeader = 'x-records-page';
export const recordsPageSizeHeader = 'x-records-page-size';


export const minPageSize = 10;


export const emptyInputValues = [ undefined, null, '' ];

export const attachmentNamePattern = /^[0-9a-f]{8}-[0-9a-f-]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}.[a-z]{3,}$/;

export const shareDrivePathOrAnyHttpLinkPattern = /^(\\\\(\d{1,3}.){3}\d{1,3}\\|http[s]?:\/\/.+\.)/;


export const UserRole = Object.freeze({
    employee: 'сотрудник',
    manager: 'завскладом',
    director: 'руководитель',
});


export const IndicatorDataType = Object.freeze({
    string: Object.freeze({
        label: 'текст',
        value: 'string',
    }),
    integer: Object.freeze({
        label: 'целое число',
        value: 'integer',
    }),
    float: Object.freeze({
        label: 'вещественное число',
        value: 'float',
    }),
    boolean: Object.freeze({
        label: 'флаг',
        value: 'boolean',
    }),
    date: Object.freeze({
        label: 'дата',
        value: 'date',
    }),
    photos: Object.freeze({
        label: 'фото',
        value: 'photos',
    }),
});


export const TaskAssignee = Object.freeze({
    warehouse: 'Склад',
    employee: 'Сотрудник',
});


export const TaskStatus = Object.freeze({
    all: '',
    inProgress: 'in_progress',
    overdue: 'overdue',
    completed: 'completed',
});


export const BackgroundJobExecutionPeriodicity = Object.freeze({
    monthly: 'ежемесячно',
    weekly: 'еженедельно',
    daily: 'ежедневно',
});
