import { useContext, useLayoutEffect } from 'react';

import api from '../core/api';
import { AuthContext } from '../hooks/contexts';


const ApiMiddleware = ({ children }) => {
    const { signIn, signOut, getAuthorizationData } = useContext(AuthContext);

    useLayoutEffect(() => {
        api.interceptors.request.use((config) => {
            const { accessToken, refreshToken } = getAuthorizationData();
            const authorizationToken = config.shouldUseRefreshTokenForAuthorization ? refreshToken : accessToken;

            if (authorizationToken) {
                config.headers.Authorization = `Bearer ${authorizationToken}`;
            }

            return config;
        });

        api.interceptors.response.use(null, async (error) => {
            if (!('response' in error)) {
                return Promise.reject({
                    message: 'Не удалось отправить запрос',
                    errorData: {},
                });
            }

            switch (error.response.status) {
                case 418:
                    try {
                        const { data } = await api.put('/auth/tokens', null, {
                            shouldUseRefreshTokenForAuthorization: true,
                        });

                        signIn(data.access_token, data.refresh_token, data.user_role);
                    } catch (error) {
                        signOut();

                        return Promise.reject(error);
                    }

                    return api(error.config);
                case 401:
                    signOut();

                    return Promise.resolve();
                case 422:
                    return Promise.reject({
                        message: error.response.data?.detail[0]?.msg || 'Получены некорректные параметры. Проверьте, пожалуйста, корректность заполнения данных',
                        errorData: {},
                    });

                default:
                    return Promise.reject({
                        message: error.response.data.message,
                        errorData: error.response.data.error_data,
                    });
            }
        });
    }, [ signIn, signOut, getAuthorizationData ]);

    return children;
};


export default ApiMiddleware;
