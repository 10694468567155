import api from '../api';
import { recordsTotalNumberHeader } from '../utilities/constants';


export const getWarehouseListOfUser = async () => {
    const response = await api.get('/warehouse/list/of-user');

    return response.data;
};


export const getWarehouseList = async (
    page,
    size,
    searchQuery
) => {
    const response = await api.get('/warehouse/list', {
        params: {
            page,
            size,
            search_query: searchQuery
        },
    });

    return [
        response.data,
        response.headers[recordsTotalNumberHeader],
    ];
};


export const createWarehouse = async (
    cityId,
    name,
) => {
    const response = await api.post('/warehouse', {
        city_id: cityId,
        name,
    });

    return response.data;
};


export const updateWarehouse = async (
    warehouseId,
    cityId,
    name,
) => {
    let updateData = {};

    if (cityId !== undefined) { updateData.city_id = cityId; }
    if (name !== undefined) { updateData.name = name; }

    const response = await api.patch('/warehouse', updateData, {
        params: {
            id: warehouseId,
        },
    });

    return response.data;
};


export const deleteWarehouse = async (warehouseId) => {
    const response = await api.delete('/warehouse', {
        params: {
            id: warehouseId,
        },
    });

    return response.data;
};
