import { useEffect, useState } from 'react';
import { Alert, Divider, Input, Pagination, Skeleton, Space } from 'antd';

import * as CityService from '../../../core/services/city';
import { minPageSize } from '../../../core/utilities/constants';

import City from '../../../components/cards/City';


const CitiesTab = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ cities, setCities ] = useState([]);
    const [ totalNumber, setTotalNumber ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ size, setSize ] = useState(minPageSize);
    const [ searchQuery, setSearchQuery ] = useState('');

    const [ creationFormReRenderingBoolean, setCreationFormReRenderingBoolean ] = useState(false);

    const fetchCities = async (
        page,
        size,
        searchQuery = null,
    ) => {
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const [ fetchedCities, totalNumber ] = await CityService.getCityList(page, size, searchQuery);

            setCities(fetchedCities);
            setTotalNumber(totalNumber);
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const setPageAndSize = (page, size) => {
        setPage(page);
        setSize(size);
    };

    useEffect(() => {
        fetchCities(page, size);
    }, [ page, size ]);


    return (
        <Space direction='vertical' size='middle' className='width--full-size'>
            <City
                key={creationFormReRenderingBoolean}
                title='Создание нового города'
                creationForm={true}
                onSubmit={() => {
                    setCreationFormReRenderingBoolean(!creationFormReRenderingBoolean);
                    fetchCities(page, size);
                }}
            />

            <Divider style={{ margin: '12px 0' }} />

            <Input.Search
                allowClear
                placeholder='Название города...'
                enterButton='Найти'
                loading={isLoading}
                value={searchQuery}
                onChange={(event) => {
                    setSearchQuery(event.target.value);
                }}
                onSearch={() => {
                    setPage(1);
                    fetchCities(page, size, searchQuery);
                }}
            />

            {
                isLoading ? <Skeleton active /> :
                errorMessage ? <Alert message={errorMessage} type='error' showIcon /> :
                totalNumber === '0' ? <Alert message='Города отсутствуют' type='info' showIcon /> : (
                    <>
                        {cities.map((city) => (
                            <City
                                key={city.id}
                                city={city}
                                onSubmit={() => {
                                    setSearchQuery('');
                                    fetchCities(page, size);
                                }}
                            />
                        ))}

                        {totalNumber > minPageSize && (
                            <Pagination
                                showSizeChanger
                                total={totalNumber}
                                current={page}
                                pageSize={size}
                                onChange={setPageAndSize}
                            />
                        )}
                    </>
                )
            }
        </Space>
    );
};


export default CitiesTab;
