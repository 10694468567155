import api from '../api';


export const createTokens = async (
    email,
    password,
) => {
    const response = await api.post('/auth/tokens', null, {
        auth: {
            username: email,
            password,
        },
    });

    return response.data;
};
