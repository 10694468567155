import { useState } from 'react';
import { Form, Input, Select, notification } from 'antd';

import * as UserService from '../../core/services/user';
import { makeRequiredRule } from '../../core/utilities/callables';

import CardWithButtonGroup from '../CardWithButtonGroup';
import { filterOption, isFormFieldValueChanged, makeFormFieldOptionGroupsSelectable } from '../utilities/callables';


const User = ({
    title,
    user = null,
    roleOptions,
    warehouseOptions,
    creationForm: isCreationForm = false,
    onSubmit,
}) => {
    const [ form ] = Form.useForm();
    const [ isBeingEdited, setIsBeingEdited ] = useState(isCreationForm);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isRoleSelectOpen, setIsRoleSelectOpen ] = useState(false);
    const [ isWarehouseSelectOpen, setIsWarehouseSelectOpen ] = useState(false);
    const [ notificator, notificationContextHolder ] = notification.useNotification();

    const initialUserWarehouseIds = user === null ? [] : user.warehouse_ids;
    const warehouseOptionIds = warehouseOptions.map(({ options }) => options.map(({ value }) => value)).flat(1);

    const idsOfUserWarehousesOwnedByManager = initialUserWarehouseIds.filter(
        (warehouseId) => warehouseOptionIds.includes(warehouseId)
    );
    const idsOfUserWarehousesNotOwnedByManager = initialUserWarehouseIds.filter(
        (warehouseId) => !warehouseOptionIds.includes(warehouseId)
    );

    const onRoleSelectOpenChange = (isOpen) => {
        setIsRoleSelectOpen(isBeingEdited && isOpen);
    };

    const onWarehouseSelectOpenChange = (isOpen) => {
        setIsWarehouseSelectOpen(isBeingEdited && isOpen);
    };

    const editUser = () => {
        if (isCreationForm) { return; }

        setIsBeingEdited(true);
    };

    const cancelEditingUser = () => {
        form.resetFields();
        setIsBeingEdited(false);
    };

    const saveUser = async () => {
        const formDataEntries = Object.entries(form.getFieldsValue()).filter(
            ([ fieldName, fieldValue ]) => isFormFieldValueChanged(user, fieldName, fieldValue, isCreationForm),
        );

        if (formDataEntries.length === 0) {
            if (!isCreationForm) {
                setIsBeingEdited(false);                
            }

            return;
        }

        try {
            await form.validateFields();
        } catch {
            return;
        }

        setIsLoading(true);

        const formData = Object.fromEntries(formDataEntries);

        try {
            if (isCreationForm) {
                await UserService.createUser(
                    formData.warehouse_ids.concat(idsOfUserWarehousesNotOwnedByManager),
                    formData.email,
                    formData.password,
                    formData.fullname,
                    formData.position,
                    formData.role,
                );
            } else {
                await UserService.updateUser(
                    user.id,
                    formData.warehouse_ids && formData.warehouse_ids.concat(idsOfUserWarehousesNotOwnedByManager),
                    formData.email,
                    formData.password,
                    formData.fullname,
                    formData.position,
                    formData.role,
                );

                setIsBeingEdited(false);
            }

            onSubmit();
        } catch (error) {
            notificator.error({
                message: `Ошибка ${isCreationForm ? 'создания' : 'обновления'} пользователя`,
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const deleteUser = async () => {
        setIsLoading(true);

        try {
            await UserService.deleteUser(user.id);

            onSubmit();
        } catch (error) {
            notificator.error({
                message: 'Ошибка удаления пользователя',
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {notificationContextHolder}

            <CardWithButtonGroup
                title={title}
                isCreationForm={isCreationForm}
                isBeingEdited={isBeingEdited}
                isLoading={isLoading}
                onEdit={editUser}
                onCancelEditing={cancelEditingUser}
                onSave={saveUser}
                onDelete={deleteUser}
            >
                <Form layout='vertical' form={form} initialValues={{
                    ...user,
                    warehouse_ids: idsOfUserWarehousesOwnedByManager,
                }}>
                    <Form.Item
                        name='fullname'
                        label='Полное имя'
                        rules={[ makeRequiredRule('Введите полное имя') ]}
                    >
                        <Input readOnly={!isBeingEdited} placeholder='...' />
                    </Form.Item>
                    <Form.Item
                        name='position'
                        label='Должность'
                        rules={[ makeRequiredRule('Введите должность') ]}
                    >
                        <Input readOnly={!isBeingEdited} placeholder='...' />
                    </Form.Item>
                    <Form.Item
                        name='role'
                        label='Роль'
                        rules={[ makeRequiredRule('Выберите роль') ]}
                    >
                        <Select
                            virtual={false}
                            showSearch={isBeingEdited}
                            placeholder='...'
                            optionFilterProp='children'
                            filterOption={filterOption}
                            options={roleOptions}
                            open={isRoleSelectOpen}
                            onDropdownVisibleChange={onRoleSelectOpenChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name='warehouse_ids'
                        label='Склады'
                        rules={[ makeRequiredRule('Выберите склады') ]}
                    >
                        <Select
                            virtual={false}
                            mode='multiple'
                            maxTagCount='responsive'
                            removeIcon={null}
                            showSearch={isBeingEdited}
                            placeholder='...'
                            optionFilterProp='children'
                            filterOption={filterOption}
                            options={makeFormFieldOptionGroupsSelectable(warehouseOptions, 'warehouse_ids', form)}
                            open={isWarehouseSelectOpen}
                            onDropdownVisibleChange={onWarehouseSelectOpenChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name='email'
                        label='Почта'
                        rules={[ makeRequiredRule('Введите почту') ]}
                    >
                        <Input readOnly={!isBeingEdited} type='email' placeholder='...' />
                    </Form.Item>
                    <Form.Item
                        name='password'
                        label='Пароль'
                        rules={isCreationForm && [ makeRequiredRule('Введите пароль') ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Input.Password readOnly={!isBeingEdited} autoComplete='new-password' placeholder={isCreationForm ? '...' : '••••••••••'} />
                    </Form.Item>
                </Form>
            </CardWithButtonGroup>
        </>
    );
};


export default User;
