import { useContext } from 'react';

import { AuthContext } from './contexts';
import { privateRoutes, publicRoutes } from '../core/routes';


export const useRoutes = () => {
    const { isAuthorized, getAuthorizationData } = useContext(AuthContext);
    const { userRole } = getAuthorizationData();

    const filteredPrivateRoutes = privateRoutes.filter((route) => route.allowedRoles === undefined || route.allowedRoles.includes(userRole));

    return isAuthorized ? filteredPrivateRoutes : publicRoutes;
};
