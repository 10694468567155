import { useState } from 'react';
import { Alert, Button, Card, Divider, Form, Input, Typography } from 'antd';
import { AppstoreAddOutlined, DeleteOutlined, ExportOutlined } from '@ant-design/icons';

import { makeRequiredRule } from '../../core/utilities/callables';

import ButtonGroup from '../ButtonGroup';
import IndicatorFormItem from './IndicatorFormItem';


const SubsectionFormItems = ({
    name,
    subsection = null,
    isCreationForm,
    isBeingEdited,
    onDelete,
    onRestore,
    replaceDeleteByRestore = false,
    hideDelete = false,
    onChangeIndicatorIdsToDelete = null,
}) => {
    const [ indicatorsToUpdate ] = useState(subsection?.indicators ?? []);
    const [ indicatorsToCreate, setIndicatorsToCreate ] = useState(isCreationForm ? [ { pseudoId: 0 } ] : []);
    const [ indicatorIdsToDelete, setIndicatorIdsToDelete ] = useState([]);

    const addIndicatorToCreate = () => {
        setIndicatorsToCreate([
            ...indicatorsToCreate,
            {
                pseudoId: (indicatorsToCreate[indicatorsToCreate.length - 1]?.pseudoId ?? -1) + 1,
            },
        ]);
    };
    const removeIndicatorToCreate = (indicatorPseudoId) => {
        setIndicatorsToCreate(indicatorsToCreate.filter((indicator) => indicator.pseudoId !== indicatorPseudoId));
    };

    const addIndicatorToDelete = (indicatorId) => {
        const newIndicatorsToDelete = [
            ...indicatorIdsToDelete,
            indicatorId,
        ];

        setIndicatorIdsToDelete(newIndicatorsToDelete);
        onChangeIndicatorIdsToDelete(newIndicatorsToDelete);
    };
    const removeIndicatorToDelete = (markedIndicatorId) => {
        const newIndicatorIdsToDelete = indicatorIdsToDelete.filter((indicatorId) => indicatorId !== markedIndicatorId);

        setIndicatorIdsToDelete(newIndicatorIdsToDelete);
        onChangeIndicatorIdsToDelete(newIndicatorIdsToDelete);
    };


    return (
        <Card type='inner' size='small' style={{ marginBottom: 12 }}>
            <Form.Item
                name={[ name, 'name' ]}
                label='Название'
                rules={!isCreationForm && [ makeRequiredRule('Введите название') ]}
                initialValue={subsection?.name}
            >
                <Input readOnly={!isBeingEdited} placeholder='...' />
            </Form.Item>

            <Typography.Title level={5} style={{ marginTop: 32, marginBottom: 12 }}>
                Показатели
            </Typography.Title>

            {indicatorsToUpdate.length === 0 && indicatorsToCreate.length === 0 ? (
                <Alert message='Показатели не добавлены' type='info' showIcon />
            ) : (
                <>
                    {indicatorsToUpdate.map((indicator) => (
                        <IndicatorFormItem
                            key={indicator.id}
                            name={[ name, `indicator-to-update-${indicator.id}` ]}
                            indicator={indicator}
                            isBeingEdited={isBeingEdited}
                            isCreationForm={isCreationForm}
                            replaceDeleteByRestore={indicatorIdsToDelete.indexOf(indicator.id) !== -1}
                            onDelete={() => { addIndicatorToDelete(indicator.id); }}
                            onRestore={() => { removeIndicatorToDelete(indicator.id); }}
                        />
                    ))}

                    {indicatorsToUpdate.length > 0 && indicatorsToCreate.length > 0 && <Divider />}

                    {indicatorsToCreate.map((indicator) => (
                        <IndicatorFormItem
                            key={indicator.pseudoId}
                            name={[ name, `indicator-to-create-${indicator.pseudoId}`]}
                            isCreationForm={isCreationForm}
                            onDelete={() => { removeIndicatorToCreate(indicator.pseudoId); }}
                            hideDelete={isCreationForm && indicatorsToCreate.length <= 1}
                        />
                    ))}
                </>
            )}

            {(isCreationForm || isBeingEdited) && (
                <ButtonGroup>
                    <Button
                        icon={<AppstoreAddOutlined />}
                        onClick={addIndicatorToCreate}
                        className='width--full-size text--left'
                    >
                        Добавить показатель
                    </Button>

                    {!hideDelete && isBeingEdited && (
                        replaceDeleteByRestore ? (
                            <Button
                                icon={<ExportOutlined />}
                                onClick={onRestore}
                                className='width--full-size text--left'
                            >
                                Восстановить
                            </Button>
                        ) : (
                            <Button
                                icon={<DeleteOutlined />}
                                danger onClick={onDelete}
                                className='width--full-size text--left'
                            >
                                Удалить
                            </Button>
                        )
                    )}
                </ButtonGroup>
            )}
        </Card>
    );
};


export default SubsectionFormItems;
