import api from '../api';
import { recordsTotalNumberHeader } from '../utilities/constants';


export const getTaskPriorityList = async () => {
    const response = await api.get('/task/priority/list');

    return response.data;
};


export const getTaskPeriodicityList = async () => {
    const response = await api.get('/task/periodicity/list');

    return response.data;
};


export const getTaskListForEmployee = async (
    taskStatus,
    page,
    size,
) => {
    const response = await api.get('/task/list/for-employee', {
        params: {
            task_status: taskStatus,
            page,
            size,
        },
    });

    return [
        response.data,
        response.headers[recordsTotalNumberHeader],
    ];
};


export const getTaskListFromEmployee = async (
    taskStatus,
    page,
    size,
) => {
    const response = await api.get('/task/list/from-employee', {
        params: {
            task_status: taskStatus,
            page,
            size,
        },
    });

    return [
        response.data,
        response.headers[recordsTotalNumberHeader],
    ];
};


export const getTaskOfEmployee = async (taskCompletionId) => {
    const response = await api.get('/task/for-employee', {
        params: {
            completion_id: taskCompletionId,
        },
    });

    return response.data;
};


export const getTaskList = async (
    assignee,
    assignee_id,
    task_status,
    page,
    size,
    searchQuery,
) => {
    const response = await api.get('/task/list', {
        params: {
            assignee: assignee,
            assignee_id: assignee_id,
            task_status,
            page,
            size,
            search_query: searchQuery,
        },
    });

    return [
        response.data,
        response.headers[recordsTotalNumberHeader],
    ];
};


export const getTaskCompletionList = async (taskId) => {
    const response = await api.get('/task/completion/list', {
        params: {
            task_id: taskId,
        },
    });

    return response.data;
};


export const createTask = async (
    warehouseIds,
    employeeIds,
    name,
    description,
    attachments,
    priority,
    deadline,
    periodicity,
    visibilityDays,
    isNotifiable,
) => {
    const response = await api.post('/task', {
        warehouse_ids: warehouseIds,
        employee_ids: employeeIds,
        name,
        description,
        attachments,
        priority,
        deadline,
        periodicity,
        visibility_days: visibilityDays,
        is_notifiable: isNotifiable,
    });

    return response.data;
};


export const updateTask = async (
    taskId,
    warehouseIds,
    employeeIds,
    name,
    description,
    attachments,
    priority,
    deadline,
    periodicity,
    visibilityDays,
    isNotifiable,
) => {
    let updateData = {};

    if (warehouseIds !== undefined) { updateData.warehouse_ids = warehouseIds; }
    if (employeeIds !== undefined) { updateData.employee_ids = employeeIds; }
    if (name !== undefined) { updateData.name = name; }
    if (description !== undefined) { updateData.description = description; }
    if (attachments !== undefined) { updateData.attachments = attachments; }
    if (priority !== undefined) { updateData.priority = priority; }
    if (deadline !== undefined) { updateData.deadline = deadline; }
    if (periodicity !== undefined) { updateData.periodicity = periodicity; }
    if (visibilityDays !== undefined) { updateData.visibility_days = visibilityDays; }
    if (isNotifiable !== undefined) { updateData.is_notifiable = isNotifiable; }

    const response = await api.patch('/task', updateData, {
        params: {
            id: taskId,
        },
    });

    return response.data;
};


export const deleteTask = async (taskId) => {
    const response = await api.delete('/task', {
        params: {
            id: taskId,
        },
    });

    return response.data;
};


export const toggleTaskCompletion = async (taskCompletionId) => {
    await api.patch('/task/completion', {}, {
        params: {
            id: taskCompletionId,
        },
    });
};
