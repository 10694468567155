export const makeRequiredRule = (message) => ({
    required: true,
    message,
});


export const formatDateTime = (dateTimeString, includeTimezone = false) => {
    if (!dateTimeString) { return dateTimeString; }

    let dateTimeFormattingOptions = {};

    if (includeTimezone) {
        dateTimeFormattingOptions.timeZoneName = 'long';
    }

    return new Date(dateTimeString).toLocaleString('ru', dateTimeFormattingOptions);
};


export const capitalizeString = (string) => {
    return string[0].toUpperCase() + string.slice(1);
};


export const formatWordOfNumber = (
    number,
    wordForOne,
    wordForTwo,
    wordForFive,
) => {
    let absoluteNumber = Math.abs(parseInt(number));

    absoluteNumber %= 100;

    if (absoluteNumber >= 5 && absoluteNumber <= 20) {
        return wordForFive;
    }

    absoluteNumber %= 10

    if (absoluteNumber === 1) {
        return wordForOne;
    }
    if (absoluteNumber >= 2 && absoluteNumber <= 4) {
        return wordForTwo;
    }

    return wordForFive;
}
