import { useEffect, useState } from 'react';
import { Card, Collapse, DatePicker, Descriptions, Divider, Form, Grid, Input, InputNumber, Select, Space, Switch, Tooltip, Typography, Upload, notification } from 'antd';
import ruLocale from 'antd/es/date-picker/locale/ru_RU';
import { CheckOutlined, CloseOutlined, CopyOutlined, InboxOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import * as AttachmentService from '../../core/services/attachment';
import * as TaskService from '../../core/services/task';
import { makeRequiredRule } from '../../core/utilities/callables';

import { filterOption, isFormFieldValueChanged, makeFormFieldOptionGroupsSelectable } from '../utilities/callables';

import CardWithButtonGroup from '../CardWithButtonGroup';
import TaskCompletionsTimeline from '../TaskCompletionsTimeline';


const defaultVisibilityDays = 14;


const formatFieldValueForOutput = (fieldValue, fieldName) => {
    switch (fieldName) {
        case 'description':
        case 'periodicity':
            return fieldValue === '' ? null : fieldValue;
        case 'attachments':
            return Array.isArray(fieldValue) ? fieldValue : fieldValue.fileList.map((fileData) => fileData.name);
        case 'deadline':
            return fieldValue?.format('YYYY-MM-DD');

        default:
            return fieldValue;
    }
};


const Task = ({
    title,
    task = null,
    priorityOptions,
    periodicityOptions,
    warehouseOptions,
    employeeOptions,
    creationForm: isCreationForm = false,
    onSubmit,
    onUseAsTemplate,
}) => {
    const [ form ] = Form.useForm();
    const [ isBeingEdited, setIsBeingEdited ] = useState(isCreationForm);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ fileList, setFileList ] = useState(task === null ? [] : task.attachments.map((attachmentName) => ({
        status: 'done',
        name: attachmentName,
        url: AttachmentService.attachmentGetterApiUrl + attachmentName,
    })));
    const [ isPrioritySelectOpen, setIsPrioritySelectOpen ] = useState(false);
    const [ isPeriodicitySelectOpen, setIsPeriodicitySelectOpen ] = useState(false);
    const [ isWarehousesSelectOpen, setIsWarehousesSelectOpen ] = useState(false);
    const [ isEmployeesSelectOpen, setIsEmployeesSelectOpen ] = useState(false);
    const [ notificator, notificationContextHolder ] = notification.useNotification();
    const [ isDatePickerOpen, setIsDatePickerOpen ] = useState(false);
    const [ isTaskCompletionsTimelineExpanded, setIsTaskCompletionsTimelineExpanded ] = useState(false);
    const viewportSize = Grid.useBreakpoint();

    const updateFileList = (newFileList) => {
        setFileList(newFileList);
        form.setFieldValue('attachments', newFileList.map((fileData) => fileData.name));
    };

    const tryToUploadAttachment = async (attachment) => {
        try {
            return await AttachmentService.uploadAttachment(attachment);
        } catch (error) {
            notificator.error({
                message: 'Ошибка загрузки файла',
                description: error.message,
                placement: 'bottom',
            });

            return null;
        }
    };
    const showUploadedAttachment = ({ action: attachmentName }) => {
        if (!attachmentName) { return; }

        updateFileList([
            ...fileList,
            {
                status: 'done',
                name: attachmentName,
                url: AttachmentService.attachmentGetterApiUrl + attachmentName,
            },
        ]);
    };
    const removeAttachment = async ({ name: photoFileName }) => {
        updateFileList(fileList.filter((fileData) => fileData.name !== photoFileName));
    };

    const onDatePickerOpenChange = (isOpen) => {
        setIsDatePickerOpen(isBeingEdited && isOpen);
    };

    const onPrioritySelectOpenChange = (isOpen) => {
        setIsPrioritySelectOpen(isBeingEdited && isOpen);
    };

    const onPeriodicitySelectOpenChange = (isOpen) => {
        setIsPeriodicitySelectOpen(isBeingEdited && isOpen);
    };

    const onWarehousesSelectOpenChange = (isOpen) => {
        setIsWarehousesSelectOpen(isBeingEdited && isOpen);
    };

    const onEmployeesSelectOpenChange = (isOpen) => {
        setIsEmployeesSelectOpen(isBeingEdited && isOpen);
    };

    const onChangeStateOfTaskCompletionTimelineCollapse = () => {
        setIsTaskCompletionsTimelineExpanded(!isTaskCompletionsTimelineExpanded);
    }

    const editTask = () => {
        if (isCreationForm) { return; }

        setIsBeingEdited(true);
    };

    const cancelEditingTask = () => {
        form.resetFields();
        setIsBeingEdited(false);
    };

    const saveTask = async () => {
        const formDataEntries = Object.entries(form.getFieldsValue()).map(
            ([ fieldName, fieldValue ]) => [ fieldName, formatFieldValueForOutput(fieldValue, fieldName) ],
        ).filter(
            ([ fieldName, fieldValue ]) => isFormFieldValueChanged(task, fieldName, fieldValue, isCreationForm),
        );

        if (formDataEntries.length === 0) {
            if (!isCreationForm) {
                setIsBeingEdited(false);                
            }

            return;
        }

        try {
            await form.validateFields();
        } catch {
            return;
        }

        setIsLoading(true);

        const formData = Object.fromEntries(formDataEntries);

        try {
            if (isCreationForm) {
                await TaskService.createTask(
                    formData.warehouse_ids,
                    formData.employee_ids,
                    formData.name,
                    formData.description,
                    formData.attachments,
                    formData.priority,
                    formData.deadline,
                    formData.periodicity,
                    formData.visibility_days || defaultVisibilityDays,
                    formData.is_notifiable,
                );
            } else {
                await TaskService.updateTask(
                    task.id,
                    formData.warehouse_ids,
                    formData.employee_ids,
                    formData.name,
                    formData.description,
                    formData.attachments,
                    formData.priority,
                    formData.deadline,
                    formData.periodicity,
                    formData.visibility_days,
                    formData.is_notifiable,
                );

                setIsBeingEdited(false);
            }

            onSubmit();
        } catch (error) {
            notificator.error({
                message: `Ошибка ${isCreationForm ? 'создания' : 'обновления'} задачи`,
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const deleteTask = async () => {
        setIsLoading(true);

        try {
            await TaskService.deleteTask(task.id);

            onSubmit();
        } catch (error) {
            notificator.error({
                message: 'Ошибка удаления задачи',
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (task === null) { return; }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {notificationContextHolder}

            <CardWithButtonGroup
                title={title}
                isCreationForm={isCreationForm}
                isBeingEdited={isBeingEdited}
                isLoading={isLoading}
                onEdit={editTask}
                onCancelEditing={cancelEditingTask}
                onSave={saveTask}
                onDelete={deleteTask}
                additionalButtonsWhileNotEditing={[
                    {
                        icon: <CopyOutlined />,
                        onClick: onUseAsTemplate,
                        style: {
                            whiteSpace: 'normal',
                            height: 'auto',
                        },
                        title: (
                            <>
                                <span style={{ whiteSpace: 'nowrap' }}>Создать задачу&nbsp;</span>
                                <span style={{ whiteSpace: 'nowrap' }}>по шаблону данной</span>
                            </>
                        ),
                    },
                ]}
            >
                {!isCreationForm && (
                    <>
                        <Space direction='vertical' size='middle' className='width--full-size'>
                            <Descriptions layout={viewportSize.lg ? 'vertical' : 'horizontal'} size='small'>
                                <Descriptions.Item label='Автор'>{task.author}</Descriptions.Item>
                            </Descriptions>
                            <Collapse
                                ghost
                                expandIconPosition='end'
                                className='collapse-without-side-padding'
                                items={[{
                                    key: 1,
                                    label: (
                                        <Tooltip placement='topLeft' title='Нажмите, чтобы раскрыть или скрыть'>
                                            <Typography.Text type='secondary'>Время завершения</Typography.Text>
                                        </Tooltip>
                                    ),
                                    children: (
                                        <TaskCompletionsTimeline
                                            isExpanded={isTaskCompletionsTimelineExpanded}
                                            taskId={task.id}
                                        />
                                    ),
                                }]}
                                onChange={onChangeStateOfTaskCompletionTimelineCollapse}
                            />
                        </Space>

                        <Divider />
                    </>
                )}

                <Form layout='vertical' form={form} initialValues={task === null ? {
                    attachments: [],
                    periodicity: '',
                    is_notifiable: true,
                } : {
                    ...task,
                    deadline: dayjs(task.deadline),
                    periodicity: task.periodicity ?? '',
                }}>
                    <Form.Item
                        name='name'
                        label='Название'
                        rules={[ makeRequiredRule('Введите название') ]}
                    >
                        <Input.TextArea
                            autoSize
                            readOnly={!isBeingEdited}
                            placeholder='...'
                        />
                    </Form.Item>
                    <Form.Item
                        name='description'
                        label='Описание'
                    >
                        <Input.TextArea
                            autoSize
                            readOnly={!isBeingEdited}
                            placeholder='...'
                        />
                    </Form.Item>
                    <Form.Item
                        name='attachments'
                        label='Файлы'
                    >
                        <Upload.Dragger
                            disabled={!isBeingEdited}
                            fileList={fileList}
                            showUploadList={{ showRemoveIcon: isBeingEdited }}
                            action={tryToUploadAttachment}
                            customRequest={showUploadedAttachment}
                            onRemove={removeAttachment}
                        >
                            <p className='ant-upload-drag-icon'>
                                <InboxOutlined />
                            </p>
                            <p className='ant-upload-text'>
                                + Добавить файл
                            </p>
                            <p className='ant-upload-hint' style={{ paddingRight: 16, paddingLeft: 16 }}>
                                Для добавления файла нажмите на данную область или перетащите его сюда
                            </p>
                        </Upload.Dragger>
                    </Form.Item>

                    <Space direction='vertical' className='width--full-size'>
                        <Typography.Text>
                            Исполнители
                        </Typography.Text>

                        <Card type='inner' size='small' style={{ marginBottom: 24 }}>
                            <Form.Item
                                name='warehouse_ids'
                                label={<Space><ShopOutlined /> Склады</Space>}
                            >
                                <Select
                                    virtual={false}
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    removeIcon={null}
                                    showSearch={isBeingEdited}
                                    placeholder='...'
                                    optionFilterProp='children'
                                    filterOption={filterOption}
                                    options={makeFormFieldOptionGroupsSelectable(warehouseOptions, 'warehouse_ids', form)}
                                    open={isWarehousesSelectOpen}
                                    onDropdownVisibleChange={onWarehousesSelectOpenChange}
                                />
                            </Form.Item>

                            <Form.Item
                                name='employee_ids'
                                label={<Space><UserOutlined /> Сотрудники</Space>}
                                style={{ marginBottom: 0 }}
                            >
                                <Select
                                    virtual={false}
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    removeIcon={null}
                                    showSearch={isBeingEdited}
                                    placeholder='...'
                                    optionFilterProp='children'
                                    filterOption={filterOption}
                                    options={makeFormFieldOptionGroupsSelectable(employeeOptions, 'employee_ids', form)}
                                    open={isEmployeesSelectOpen}
                                    onDropdownVisibleChange={onEmployeesSelectOpenChange}
                                />
                            </Form.Item>
                        </Card>
                    </Space>

                    <Form.Item
                        name='priority'
                        label='Приоритет'
                        rules={[ makeRequiredRule('Выберите приоритет') ]}
                    >
                        <Select
                            virtual={false}
                            showSearch={isBeingEdited}
                            placeholder='...'
                            optionFilterProp='children'
                            filterOption={filterOption}
                            options={priorityOptions}
                            open={isPrioritySelectOpen}
                            onDropdownVisibleChange={onPrioritySelectOpenChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name='deadline'
                        label='Срок'
                        rules={[ makeRequiredRule('Выберите срок') ]}
                    >
                        <DatePicker
                            inputReadOnly={!isBeingEdited}
                            open={isBeingEdited && isDatePickerOpen}
                            onOpenChange={onDatePickerOpenChange}
                            locale={ruLocale}
                            format='DD.MM.YYYY'
                            allowClear={false}
                            placeholder='...'
                            className='width--full-size'
                        />
                    </Form.Item>
                    <Form.Item
                        name='periodicity'
                        label='Периодичность'
                    >
                        <Select
                            virtual={false}
                            showSearch={isBeingEdited}
                            placeholder='...'
                            optionFilterProp='children'
                            filterOption={filterOption}
                            options={periodicityOptions}
                            open={isPeriodicitySelectOpen}
                            onDropdownVisibleChange={onPeriodicitySelectOpenChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name='visibility_days'
                        label='Количество дней до срока, за которое задача становится видна исполнителям'
                    >
                        <InputNumber
                            readOnly={!isBeingEdited}
                            placeholder={isCreationForm ? `${defaultVisibilityDays} (значение по умолчанию)` : '...'}
                            className='width--full-size'
                        />
                    </Form.Item>
                    <Form.Item
                        name='is_notifiable'
                        label='Отправлять уведомления'
                        valuePropName='checked'
                        style={{ marginBottom: 0 }}
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            disabled={!isBeingEdited}
                            style={{ width: 60 }}
                        />
                    </Form.Item>
                </Form>
            </CardWithButtonGroup>
        </>
    );
};


export default Task;
