import { useMemo, useState } from 'react';
import { Descriptions, Divider, Form, InputNumber, Select, TimePicker, notification } from 'antd';
import ruLocale from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';

import * as BackgroundJobService from '../../core/services/backgroundJob';
import { formatDateTime, makeRequiredRule } from '../../core/utilities/callables';
import { BackgroundJobExecutionPeriodicity } from '../../core/utilities/constants';

import CardWithButtonGroup from '../CardWithButtonGroup';
import { filterOption, isFormFieldValueChanged } from '../utilities/callables';


const getInitialBackgroundJobExecutionPeriodicity = (backgroundJob) => {
    if (backgroundJob === null) {
        return BackgroundJobExecutionPeriodicity.daily;
    }

    if (backgroundJob.trigger.day_of_month === null && backgroundJob.trigger.day_of_week === null) {
        return BackgroundJobExecutionPeriodicity.daily;
    }
    if (backgroundJob.trigger.day_of_month === null) {
        return BackgroundJobExecutionPeriodicity.weekly
    }
    if (backgroundJob.trigger.day_of_week === null) {
        return BackgroundJobExecutionPeriodicity.monthly;
    }

    return null;
}


const BackgroundJob = ({
    title,
    backgroundJob = null,
    backgroundJobIdOptions,
    creationForm: isCreationForm = false,
    onSubmit,
}) => {
    const [ form ] = Form.useForm();
    const [ isBeingEdited, setIsBeingEdited ] = useState(isCreationForm);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ notificator, notificationContextHolder ] = notification.useNotification();
    const [ isBackgroundJobIdSelectOpen, setIsBackgroundJobIdSelectOpen ] = useState(false);
    const [ backgroundJobExecutionPeriodicity, setBackgroundJobExecutionPeriodicity ] = useState(getInitialBackgroundJobExecutionPeriodicity(backgroundJob));
    const [ isBackgroundJobExecutionPeriodicitySelectOpen, setIsBackgroundJobExecutionPeriodicitySelectOpen ] = useState(false);
    const [ isDayOfWeekSelectOpen, setIsDayOfWeekSelectOpen ] = useState(false);
    const [ isTimePickerOpen, setIsTimePickerOpen ] = useState(false);

    const backgroundJobExecutionPeriodicityOptions = useMemo(() => Object.values(BackgroundJobExecutionPeriodicity).map((executionPeriodicity) => ({
        label: executionPeriodicity,
        value: executionPeriodicity,
    })), []);

    const dayOfWeekOptions = useMemo(() => [
        { label: 'Понедельник', value: 0 },
        { label: 'Вторник', value: 1 },
        { label: 'Среда', value: 2 },
        { label: 'Четверг', value: 3 },
        { label: 'Пятница', value: 4 },
        { label: 'Суббота', value: 5 },
        { label: 'Воскресенье', value: 6 },
    ], []);

    const onBackgroundJobIdSelectOpenChange = (isOpen) => {
        setIsBackgroundJobIdSelectOpen(isBeingEdited && isOpen);
    };

    const onBackgroundJobExecutionPeriodicitySelectOpenChange = (isOpen) => {
        setIsBackgroundJobExecutionPeriodicitySelectOpen(isBeingEdited && isOpen);
    };

    const onDayOfWeekSelectOpenChange = (isOpen) => {
        setIsDayOfWeekSelectOpen(isBeingEdited && isOpen);
    };

    const onTimePickerOpenChange = (isOpen) => {
        setIsTimePickerOpen(isBeingEdited && isOpen);
    };

    const editBackgroundJob = () => {
        if (isCreationForm) { return; }

        setIsBeingEdited(true);
    };

    const cancelEditingBackgroundJob = () => {
        form.resetFields();
        setIsBeingEdited(false);
    };

    const saveBackgroundJob = async () => {
        const formDataEntries = Object.entries(form.getFieldsValue()).filter(
            ([ fieldName, fieldValue ]) => isFormFieldValueChanged(backgroundJob, fieldName, fieldValue, isCreationForm),
        );

        if (formDataEntries.length === 0) {
            if (!isCreationForm) {
                setIsBeingEdited(false);
            }

            return;
        }

        try {
            await form.validateFields();
        } catch {
            return;
        }

        setIsLoading(true);

        const formData = Object.fromEntries(formDataEntries);

        try {
            const dayOfMonth = backgroundJobExecutionPeriodicity === BackgroundJobExecutionPeriodicity.monthly ? formData.day_of_month : undefined;
            const dayOfWeek = backgroundJobExecutionPeriodicity === BackgroundJobExecutionPeriodicity.weekly ? formData.day_of_week : undefined;

            if (isCreationForm) {
                await BackgroundJobService.createBackgroundJob(
                    formData.id,
                    dayOfMonth,
                    dayOfWeek,
                    formData.time?.hour(),
                    formData.time?.minute(),
                );
            } else {
                await BackgroundJobService.updateBackgroundJobSchedule(
                    backgroundJob.id,
                    dayOfMonth,
                    dayOfWeek,
                    formData.time?.hour(),
                    formData.time?.minute(),
                );

                setIsBeingEdited(false);
            }

            onSubmit();
        } catch (error) {
            notificator.error({
                message: `Ошибка ${isCreationForm ? 'создания' : 'обновления'} фонового процесса`,
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const deleteBackgroundJob = async () => {
        setIsLoading(true);

        try {
            await BackgroundJobService.deleteBackgroundJob(backgroundJob.id);

            onSubmit();
        } catch (error) {
            notificator.error({
                message: 'Ошибка удаления фонового процесса',
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {notificationContextHolder}

            <CardWithButtonGroup
                title={title}
                isCreationForm={isCreationForm}
                isBeingEdited={isBeingEdited}
                isLoading={isLoading}
                onEdit={editBackgroundJob}
                onCancelEditing={cancelEditingBackgroundJob}
                onSave={saveBackgroundJob}
                onDelete={deleteBackgroundJob}
            >
                <Form layout='vertical' form={form} initialValues={backgroundJob && {
                    id: backgroundJob.id,
                    execution_periodicity: backgroundJobExecutionPeriodicity,
                    day_of_month: backgroundJob.trigger.day_of_month,
                    day_of_week: backgroundJob.trigger.day_of_week,
                    time: dayjs().hour(backgroundJob.trigger.hour).minute(backgroundJob.trigger.minute),
                }}>
                    {isCreationForm && (
                        <Form.Item
                            name='id'
                            label='Идентификатор'
                            rules={[ makeRequiredRule('Выберите фоновый процесс') ]}
                        >
                            <Select
                                virtual={false}
                                showSearch={isBeingEdited}
                                placeholder='...'
                                optionFilterProp='children'
                                filterOption={filterOption}
                                options={backgroundJobIdOptions}
                                open={isBackgroundJobIdSelectOpen}
                                onDropdownVisibleChange={onBackgroundJobIdSelectOpenChange}
                            />
                        </Form.Item>
                    )}

                    {!isCreationForm && (
                        <>
                            <Descriptions layout='vertical' size='small'>
                                <Descriptions.Item label='Следующий запуск'>
                                    {formatDateTime(backgroundJob.next_run_time, true)}
                                </Descriptions.Item>
                            </Descriptions>

                            <Divider />
                        </>
                    )}

                    <Form.Item
                        name='execution_periodicity'
                        label='Периодичность выполнения'
                        rules={[ makeRequiredRule('Выберите периодичность выполнения') ]}
                    >
                        <Select
                            virtual={false}
                            showSearch={isBeingEdited}
                            placeholder='...'
                            optionFilterProp='children'
                            filterOption={filterOption}
                            options={backgroundJobExecutionPeriodicityOptions}
                            open={isBackgroundJobExecutionPeriodicitySelectOpen}
                            onDropdownVisibleChange={onBackgroundJobExecutionPeriodicitySelectOpenChange}
                            onChange={setBackgroundJobExecutionPeriodicity}
                        />
                    </Form.Item>

                    {backgroundJobExecutionPeriodicity === BackgroundJobExecutionPeriodicity.monthly && (
                        <Form.Item
                            name='day_of_month'
                            label='День месяца'
                            tooltip={
                                <>
                                    <p style={{ marginBottom: 7.5 }}>Можно указать день месяца от 1 до 31. Если дня в месяце нет (например, 31), процесс отложится до месяца, в котором он есть.</p>
                                    <p>Также можно указать день с конца месяца от -3 до -1, где -1 — последний день месяца (например, 28, 30 или 31).</p>
                                </>
                            }
                            rules={[
                                makeRequiredRule('Выберите день месяца'),
                                () => ({
                                    validator(_, value) {
                                        return value === 0 ? Promise.reject(new Error('День месяца не может быть 0')) : Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <InputNumber
                                readOnly={!isBeingEdited}
                                placeholder='От 1 до 31 или от -3 до -1'
                                min={-3}
                                max={31}
                                className='width--full-size'
                            />
                        </Form.Item>
                    )}

                    {backgroundJobExecutionPeriodicity === BackgroundJobExecutionPeriodicity.weekly && (
                        <Form.Item
                            name='day_of_week'
                            label='День недели'
                            rules={[ makeRequiredRule('Выберите день недели') ]}
                        >
                            <Select
                                virtual={false}
                                showSearch={isBeingEdited}
                                placeholder='...'
                                optionFilterProp='children'
                                filterOption={filterOption}
                                options={dayOfWeekOptions}
                                open={isDayOfWeekSelectOpen}
                                onDropdownVisibleChange={onDayOfWeekSelectOpenChange}
                            />
                        </Form.Item>
                    )}

                    <Form.Item
                        name='time'
                        label='Время'
                        rules={[ makeRequiredRule('Выберите время') ]}
                        style={{ marginBottom: 0 }}
                    >
                        <TimePicker
                            changeOnBlur
                            format='HH:mm'
                            locale={ruLocale}
                            allowClear={false}
                            inputReadOnly={!isBeingEdited}
                            placeholder='...'
                            open={isBeingEdited && isTimePickerOpen}
                            onOpenChange={onTimePickerOpenChange}
                            className='width--full-size'
                        />
                    </Form.Item>
                </Form>
            </CardWithButtonGroup>
        </>
    );
};


export default BackgroundJob;
