import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FloatButton, Modal, Space } from 'antd';
import { CloseCircleOutlined, MenuOutlined } from '@ant-design/icons';

import { AuthContext } from '../hooks/contexts';
import { useCurrentRoute } from '../hooks/useCurrentRoute';
import { useMenuButtonBaseMargin } from '../hooks/useMenuButtonBaseMargin';
import { useRoutes } from '../hooks/useRoutes';
import { useWindowSize } from '../hooks/useWindowSize';


const NavigationMenu = () => {
    const { signOut } = useContext(AuthContext);
    const [ isMenuOpen, setIsMenuOpen ] = useState(false);
    const routes = useRoutes();
    const currentRoute = useCurrentRoute();
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const menuButtonBaseMargin = useMenuButtonBaseMargin();

    const openMenu = () => {
        setIsMenuOpen(true);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const onMenuOptionClick = (path) => {
        closeMenu();
        navigate(path);
    };

    const menuButtonBottomMargin = `calc(${menuButtonBaseMargin}px + env(safe-area-inset-bottom))`;
    const menuButtonLeftMargin = `calc(${menuButtonBaseMargin}px + env(safe-area-inset-left))`;

    const menuModalWidth = 275;
    const menuModalHeight = 104 + 40 * routes.length + (routes.length > 0 ? 8 : 0);
    const menuModalRightMargin = `calc(${windowSize.width / 2}px - ${menuModalWidth / 2}px - ${menuButtonLeftMargin})`;
    const menuModalTopMargin = `calc(${windowSize.height}px - ${menuModalHeight}px - ${menuButtonBottomMargin}`;

    return (
        <>
            <Modal
                title='Меню'
                footer={null}
                open={isMenuOpen}
                width={menuModalWidth}
                onCancel={closeMenu}
                style={{
                    paddingBottom: 0,
                    top: menuModalTopMargin,
                    right: menuModalRightMargin,
                }}
            >
                <Space direction='vertical' size='middle' className='width--full-size'>
                    <Space direction='vertical' className='width--full-size'>
                        {routes.map((route) => (
                            <Button
                                key={route.path}
                                block
                                type={(route.path === currentRoute?.path) ? 'primary' : 'default'}
                                icon={route.icon}
                                onClick={() => { onMenuOptionClick(route.path) }}
                                className='text--left'
                            >
                                {route.title}
                            </Button>
                        ))}
                    </Space>

                    <Button
                        block
                        danger
                        icon={<CloseCircleOutlined />}
                        onClick={signOut}
                        className='text--left'
                    >
                        Выйти
                    </Button>
                </Space>
            </Modal>

            <FloatButton
                type='primary'
                shape='square'
                icon={<MenuOutlined />}
                onClick={openMenu}
                style={{
                    bottom: menuButtonBottomMargin,
                    left: menuButtonLeftMargin,
                    boxShadow: '0 0 6px rgba(0, 0, 0, 0.4)'
                }}
            />
        </>
    );
};


export default NavigationMenu;
