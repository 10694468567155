import { Button, Form, Input } from 'antd';
import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';

import { shareDrivePathOrAnyHttpLinkPattern } from '../../core/utilities/constants';


const FilePathsFormList = ({
    name,
    label = null,
    tooltip = null,
    initialValue,
    isBeingEdited,
}) => {
    return (
        <Form.List
            name={name}
            initialValue={initialValue}
        >
            {(fields, { add, remove }) => (
                <>
                    {fields.map((field, fieldIndex) => (
                        <Form.Item
                            key={field.key}
                            label={fieldIndex === 0 && label}
                            tooltip={fieldIndex === 0 && tooltip}
                            style={{ marginBottom: 8 }}
                        >
                            <Form.Item
                                {...field}
                                validateTrigger={[ 'onChange', 'onBlur' ]}
                                rules={[
                                    {
                                        message: 'Некорректный путь или ссылка',
                                        validator: (_, value) => value?.search(shareDrivePathOrAnyHttpLinkPattern) === 0 ? Promise.resolve() : Promise.reject(),
                                    },
                                ]}
                                noStyle
                            >
                                <Input.TextArea
                                    autoSize
                                    placeholder='Вставьте путь...'
                                    readOnly={!isBeingEdited}
                                    style={{
                                        fontFamily: 'monospace',
                                        fontSize: 12,
                                        width: isBeingEdited ? 'calc(100% - 40px)' : '100%',
                                        transition: 'none',
                                    }}
                                />
                            </Form.Item>

                            {isBeingEdited && (
                                <Button
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() => { remove(field.name); }}
                                    style={{ width: 32, marginLeft: 8 }}
                                />
                            )}
                        </Form.Item>
                    ))}

                    {isBeingEdited && (
                        <Form.Item style={{ paddingTop: 8 }}>
                            <Button
                                type='dashed'
                                icon={<FileAddOutlined />}
                                onClick={() => { add(); }}
                            >
                                Добавить путь
                            </Button>
                        </Form.Item>
                    )}
                </>
            )}
        </Form.List>
    )
};


export default FilePathsFormList;
