import { useMemo } from 'react';
import { Layout } from 'antd';

import { useMenuButtonBaseMargin } from '../../hooks/useMenuButtonBaseMargin';
import { useWindowSize } from '../../hooks/useWindowSize';


const BaseLayout = ({ children }) => {
    const windowSize = useWindowSize();
    const menuButtonBaseMargin = useMenuButtonBaseMargin();

    const layoutBottomMargin = useMemo(() => menuButtonBaseMargin + 40, [ menuButtonBaseMargin ]);


    return (
        <Layout style={{
            minHeight: `calc(${windowSize.height}px - ${layoutBottomMargin}px - env(safe-area-inset-bottom) - env(safe-area-inset-top))`,
            marginBottom: layoutBottomMargin,
            paddingLeft: 'env(safe-area-inset-left)',
            paddingRight: 'env(safe-area-inset-right)',
        }}>
            {children}
        </Layout>
    );
};


export default BaseLayout;
