import { useEffect, useState } from 'react';
import { Alert, Pagination, Skeleton, Space } from 'antd';

import * as SectionService from '../../../core/services/section';
import { minPageSize } from '../../../core/utilities/constants';

import SectionWithEntries from '../../../components/cards/SectionWithEntries';


const SectionsTab = ({ warehouseId }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ sections, setSections ] = useState([]);
    const [ totalNumber, setTotalNumber ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ size, setSize ] = useState(minPageSize);

    const fetchSections = async (warehouseId, page, size) => {
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const [ fetchedSections, totalNumber ] = await SectionService.getSectionListWithEntries(warehouseId, page, size);

            fetchedSections.forEach((section) => {
                section.entries = section.entries.sort(({ created_at: lhs }, { created_at: rhs }) => lhs.localeCompare(rhs));
            });

            setSections(fetchedSections);
            setTotalNumber(totalNumber);
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const setPageAndSize = (page, size) => {
        setPage(page);
        setSize(size);
    };

    useEffect(() => {
        fetchSections(warehouseId, page, size);
    }, [ warehouseId, page, size ]);


    if (isLoading) { return <Skeleton active />; }
    if (errorMessage) { return <Alert message={errorMessage} type='error' showIcon />; }
    if (totalNumber === '0') { return <Alert message='Показатели к заполнению отсутствуют' type='info' showIcon />; }

    return (
        <Space direction='vertical' size='middle' className='width--full-size'>
            {sections.map((section) => (
                <SectionWithEntries
                    key={section.id}
                    section={section}
                    warehouseId={warehouseId}
                    onSubmit={() => {
                        fetchSections(warehouseId, page, size);
                    }}
                />
            ))}

            {totalNumber > minPageSize && (
                <Pagination showSizeChanger total={totalNumber} current={page} pageSize={size} onChange={setPageAndSize} />
            )}
        </Space>
    );
};


export default SectionsTab;
