import api from '../api';
import { recordsTotalNumberHeader } from '../utilities/constants';


export const getBackgroundJobIdList = async () => {
    const response = await api.get('/background-job/id/list');

    return response.data;
};


export const getBackgroundJobList = async (
    page,
    size,
    searchQuery
) => {
    const response = await api.get('/background-job/list', {
        params: {
            page,
            size,
            search_query: searchQuery,
        },
    });

    return [
        response.data,
        response.headers[recordsTotalNumberHeader],
    ];
};


export const createBackgroundJob = async (
    backgroundJobId,
    dayOfMonth,
    dayOfWeek,
    hour,
    minute,
) => {
    let trigger = {
        hour,
        minute,
    };

    if (dayOfMonth !== undefined) { trigger.day_of_month = dayOfMonth; }
    if (dayOfWeek !== undefined) { trigger.day_of_week = dayOfWeek; }

    const response = await api.post('/background-job', {
        id: backgroundJobId,
        trigger: trigger,
    });

    return response.data;
};


export const updateBackgroundJobSchedule = async (
    backgroundJobId,
    dayOfMonth,
    dayOfWeek,
    hour,
    minute,
) => {
    let updateData = {};

    if (dayOfMonth !== undefined) { updateData.day_of_month = dayOfMonth; }
    if (dayOfWeek !== undefined) { updateData.day_of_week = dayOfWeek; }
    if (hour !== undefined) { updateData.hour = hour; }
    if (minute !== undefined) { updateData.minute = minute; }

    const response = await api.patch('/background-job/schedule', updateData, {
        params: {
            id: backgroundJobId,
        },
    });

    return response.data;
};


export const deleteBackgroundJob = async (backgroundJobId) => {
    const response = await api.delete('/background-job', {
        params: {
            id: backgroundJobId,
        },
    });

    return response.data;
};
