import { useEffect, useState } from 'react';
import { Alert, Divider, Input, Pagination, Skeleton, Space } from 'antd';

import * as CityService from '../../../core/services/city';
import * as WarehouseService from '../../../core/services/warehouse';
import { minPageSize } from '../../../core/utilities/constants';

import Warehouse from '../../../components/cards/Warehouse';


const WarehousesTab = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ warehouses, setWarehouses ] = useState([]);
    const [ totalNumber, setTotalNumber ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ size, setSize ] = useState(minPageSize);
    const [ cityOptions, setCityOptions ] = useState([]);
    const [ searchQuery, setSearchQuery ] = useState('');

    const [ creationFormReRenderingBoolean, setCreationFormReRenderingBoolean ] = useState(false);

    const fetchWarehouses = async (
        page,
        size,
        searchQuery = null,
    ) => {
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const [
                [ fetchedWarehouses, totalNumber ],
                [ fetchedCities ],
            ] = await Promise.all([
                WarehouseService.getWarehouseList(page, size, searchQuery),
                CityService.getCityList(null, null),
            ]);

            setWarehouses(fetchedWarehouses);
            setTotalNumber(totalNumber);

            setCityOptions(
                fetchedCities.map((city) => ({
                    label: city.name,
                    value: city.id,
                })),
            );
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const setPageAndSize = (page, size) => {
        setPage(page);
        setSize(size);
    };

    useEffect(() => {
        fetchWarehouses(page, size);
    }, [ page, size ]);


    return (
        <>
            <Space direction='vertical' size='middle' className='width--full-size'>
                <Warehouse
                    key={creationFormReRenderingBoolean}
                    title='Создание нового склада'
                    cityOptions={cityOptions}
                    creationForm={true}
                    onSubmit={() => {
                        setCreationFormReRenderingBoolean(!creationFormReRenderingBoolean);
                        fetchWarehouses(page, size);
                    }}
                />

                <Divider style={{ margin: '12px 0' }} />

                <Input.Search
                    allowClear
                    placeholder='Название склада...'
                    enterButton='Найти'
                    loading={isLoading}
                    value={searchQuery}
                    onChange={(event) => {
                        setSearchQuery(event.target.value);
                    }}
                    onSearch={() => {
                        setPage(1);
                        fetchWarehouses(page, size, searchQuery);
                    }}
                />

                {
                    isLoading ? <Skeleton active /> :
                    errorMessage ? <Alert message={errorMessage} type='error' showIcon /> :
                    totalNumber === '0' ? <Alert message='Склады отсутствуют' type='info' showIcon /> : (
                        <>
                            {warehouses.map((warehouse) => (
                                <Warehouse
                                    key={warehouse.id}
                                    warehouse={warehouse}
                                    cityOptions={cityOptions}
                                    onSubmit={() => {
                                        setSearchQuery('');
                                        fetchWarehouses(page, size);
                                    }}
                                />
                            ))}

                            {totalNumber > minPageSize && (
                                <Pagination
                                    showSizeChanger
                                    total={totalNumber}
                                    current={page}
                                    pageSize={size}
                                    onChange={setPageAndSize}
                                />
                            )}
                        </>
                    )
                }
            </Space>
        </>
    );
};


export default WarehousesTab;
