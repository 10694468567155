import { useState } from 'react';
import { Layout, Tabs, Typography } from 'antd';

import * as TaskService from '../../core/services/task';
import { TaskStatus } from '../../core/utilities/constants';

import TaskForEmployee from '../../components/cards/TaskForEmployee';
import TaskFromEmployee from '../../components/cards/TaskFromEmployee';
import BaseLayout from '../../components/layouts/BaseLayout';

import TasksTab from './tabs/TasksTab';

import { changeTaskStatusTab } from './utilities/callables';
import { taskStatusTabs } from './utilities/constants';


const TasksAdministrativeCompactPage = () => {
    
    const [ isForEmployeeTabSelected, setIsForEmployeeTabSelected ] = useState(true);
    const [ tasksStatus, setTasksStatus ] = useState(TaskStatus.inProgress);
    const [ currentTasksTabKey, setCurrentTasksTabKey ] = useState(taskStatusTabs[0].key);

    const selectForEmployee = (newIsForEmployeeTabSelected) => {
        setIsForEmployeeTabSelected(newIsForEmployeeTabSelected);
    };


    return (
        <BaseLayout>
            <Typography.Title
                level={3}
                onClick={() => { selectForEmployee(true); }}
                className='layout__surtitle'
            >
                Задачи
            </Typography.Title>
            <Typography.Title className='layout__title title-tabs'>
                <span
                    onClick={() => { selectForEmployee(true); }}
                    className='title-tab'
                    style={{
                        color: isForEmployeeTabSelected ? 'var(--title--enabled)' : 'var(--title--disabled)'
                    }}
                >
                    Мои
                </span>
                <span
                    onClick={() => { selectForEmployee(false); }}
                    className='title-tab'
                    style={{
                        color: !isForEmployeeTabSelected ? 'var(--title--enabled)' : 'var(--title--disabled)'
                    }}
                >
                    От меня
                </span>
            </Typography.Title>

            <Layout.Content className='layout__content'>
                <Tabs
                    activeKey={currentTasksTabKey}
                    items={taskStatusTabs}
                    onChange={(tabKey) => {
                        changeTaskStatusTab(
                            tabKey,
                            setCurrentTasksTabKey,
                            setTasksStatus,
                        );
                    }}
                />

                <TasksTab
                    taskStatus={tasksStatus}
                    getTaskList={isForEmployeeTabSelected ? TaskService.getTaskListForEmployee : TaskService.getTaskListFromEmployee}
                    Task={isForEmployeeTabSelected ? TaskForEmployee : TaskFromEmployee}
                />
            </Layout.Content>
        </BaseLayout>
    );
};


export default TasksAdministrativeCompactPage;
