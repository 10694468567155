import { Grid, Space } from 'antd';


const ButtonGroup = ({
    style,
    children,
}) => {
    const viewportSize = Grid.useBreakpoint();

    return (
        <Space
            wrap
            direction={viewportSize.lg ? 'horizontal' : 'vertical'}
            style={{
                marginTop: 20,
                ...(viewportSize.lg ? { float: 'right' } : { width: '100%' }),
                ...style,
            }}
        >
            {children}
        </Space>
    );
};


export default ButtonGroup;
