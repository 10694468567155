import { useState } from 'react';
import { Tabs } from 'antd';

import { UserRole } from '../../core/utilities/constants';
import { useCurrentUserRole } from '../../hooks/useCurrentUserRole';

import BackgroundJobsTab from './tabs/BackgroundJobsTab';
import CitiesTab from './tabs/CitiesTab';
import SectionsTab from './tabs/SectionsTab';
import TasksTab from './tabs/TasksTab';
import UsersTab from './tabs/UsersTab';
import WarehousesTab from './tabs/WarehousesTab';


const ControlPage = () => {
    const currentUserRole = useCurrentUserRole();

    const tabs = [
        { key: 1, label: 'Города', allowedRoles: [ UserRole.director ] },
        { key: 2, label: 'Склады', allowedRoles: [ UserRole.director ] },
        { key: 3, label: 'Пользователи', allowedRoles: [ UserRole.director, UserRole.manager ] },
        { key: 4, label: 'Задачи', allowedRoles: [ UserRole.director, UserRole.manager ] },
        { key: 5, label: 'Разделы и показатели', allowedRoles: [ UserRole.director ] },
        { key: 6, label: 'Фоновые процессы', allowedRoles: [ UserRole.director ] },
    ];
    const allowedTabs = tabs.filter((tab) => tab.allowedRoles.includes(currentUserRole));

    const [ currentTabKey, setCurrentTabKey ] = useState(allowedTabs[0].key);

    const changeTab = (tabKey) => {
        setCurrentTabKey(parseInt(tabKey));
    };

    return (
        <>
            <Tabs activeKey={currentTabKey} items={allowedTabs} onChange={changeTab} />

            {currentTabKey === 1 && <CitiesTab />}
            {currentTabKey === 2 && <WarehousesTab />}
            {currentTabKey === 3 && <UsersTab />}
            {currentTabKey === 4 && <TasksTab />}
            {currentTabKey === 5 && <SectionsTab />}
            {currentTabKey === 6 && <BackgroundJobsTab />}
        </>
    );
};


export default ControlPage;
