import { Navigate, Route, Routes } from 'react-router-dom';

import { useRoutes } from '../hooks/useRoutes';


const AppRouter = () => {
    const routes = useRoutes();

    return (
        <Routes>
            {routes.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={route.page}
                />
            ))}

            <Route path='*' element={<Navigate to={routes[0]?.path ?? '/'} />} />
        </Routes>
    );
};


export default AppRouter;
