import { useState } from 'react';
import { Form, Input, notification } from 'antd';

import * as CityService from '../../core/services/city';
import { makeRequiredRule } from '../../core/utilities/callables';

import CardWithButtonGroup from '../CardWithButtonGroup';
import { isFormFieldValueChanged } from '../utilities/callables';


const City = ({
    title,
    city = null,
    creationForm: isCreationForm = false,
    onSubmit,
}) => {
    const [ form ] = Form.useForm();
    const [ isBeingEdited, setIsBeingEdited ] = useState(isCreationForm);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ notificator, notificationContextHolder ] = notification.useNotification();

    const editCity = () => {
        if (isCreationForm) { return; }

        setIsBeingEdited(true);
    };

    const cancelEditingCity = () => {
        form.resetFields();
        setIsBeingEdited(false);
    };

    const saveCity = async () => {
        const formDataEntries = Object.entries(form.getFieldsValue()).filter(
            ([ fieldName, fieldValue ]) => isFormFieldValueChanged(city, fieldName, fieldValue, isCreationForm),
        );

        if (formDataEntries.length === 0) {
            if (!isCreationForm) {
                setIsBeingEdited(false);                
            }

            return;
        }

        try {
            await form.validateFields();
        } catch {
            return;
        }

        setIsLoading(true);

        const formData = Object.fromEntries(formDataEntries);

        try {
            if (isCreationForm) {
                await CityService.createCity(formData.name);
            } else {
                await CityService.updateCity(city.id, formData.name);

                setIsBeingEdited(false);
            }

            onSubmit();
        } catch (error) {
            notificator.error({
                message: `Ошибка ${isCreationForm ? 'создания' : 'обновления'} города`,
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const deleteCity = async () => {
        setIsLoading(true);

        try {
            await CityService.deleteCity(city.id);

            onSubmit();
        } catch (error) {
            notificator.error({
                message: 'Ошибка удаления города',
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {notificationContextHolder}

            <CardWithButtonGroup
                title={title}
                isCreationForm={isCreationForm}
                isBeingEdited={isBeingEdited}
                isLoading={isLoading}
                onEdit={editCity}
                onCancelEditing={cancelEditingCity}
                onSave={saveCity}
                onDelete={deleteCity}
            >
                <Form layout='vertical' form={form} initialValues={{ ...city }}>
                    <Form.Item
                        name='name'
                        label='Название'
                        rules={[ makeRequiredRule('Введите название') ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Input readOnly={!isBeingEdited} placeholder='...' />
                    </Form.Item>
                </Form>
            </CardWithButtonGroup>
        </>
    );
};


export default City;
