import { useEffect, useState } from 'react';
import { Alert, Skeleton, Tabs } from 'antd';

import * as WarehouseService from '../../core/services/warehouse';

import SectionsTab from './tabs/SectionsTab';


const EntriesPage = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ warehouseTabs, setWarehouseTabs ] = useState([]);
    const [ warehouseId, setWarehouseId ] = useState(0);

    const changeTab = (warehouseId) => {
        setWarehouseId(parseInt(warehouseId));
    };

    const fetchWarehouseListOfUser = async () => {
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const fetchedWarehouseListOfUser = await WarehouseService.getWarehouseListOfUser();

            let warehouseTabs = fetchedWarehouseListOfUser
                .sort(({ name: lhs }, { name: rhs }) => lhs.localeCompare(rhs))
                .map((warehouse) => ({
                    key: warehouse.id,
                    label: warehouse.name,
                }));

            setWarehouseTabs(warehouseTabs);
            setWarehouseId(warehouseTabs[0].key);
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchWarehouseListOfUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (isLoading) { return <Skeleton active />; }
    if (errorMessage) { return <Alert message={errorMessage} type='error' showIcon />; }
    if (warehouseTabs.length === 0) { return <Alert message='Вы не прикреплены ни к одному складу' type='info' showIcon />; }

    return (
        <>
            <Tabs activeKey={warehouseId} items={warehouseTabs} onChange={changeTab} />

            <SectionsTab warehouseId={warehouseId} />
        </>
    );
};


export default EntriesPage;
