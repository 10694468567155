import { useState } from 'react';
import { Form, Modal, Upload, notification } from 'antd';

import * as AttachmentService from '../core/services/attachment';


const PhotoUpload = ({
    name,
    label,
    initialFileList,
    isBeingEdited,
}) => {
    const [ notificator, notificationContextHolder ] = notification.useNotification();
    const form = Form.useFormInstance();

    const [ fileList, setFileList ] = useState(initialFileList || []);
    const [ previewedPhotoUrl, setPreviewedPhotoUrl ] = useState(null);

    const updateFileList = (newFileList) => {
        setFileList(newFileList);
        form.setFieldValue(name, newFileList.map((fileData) => fileData.name));
    };

    const tryToUploadPhoto = async (photoFile) => {
        try {
            return await AttachmentService.uploadAttachment(photoFile);
        } catch (error) {
            notificator.error({
                message: 'Ошибка загрузки фото',
                description: error.message,
                placement: 'bottom',
            });

            return null;
        }
    };
    const showUploadedPhoto = ({ action: photoFileName }) => {
        if (!photoFileName) { return; }

        updateFileList([
            ...fileList,
            {
                status: 'done',
                name: photoFileName,
                url: AttachmentService.attachmentGetterApiUrl + photoFileName,
            },
        ]);
    };
    const removePhoto = async ({ name: photoFileName }) => {
        updateFileList(fileList.filter((fileData) => fileData.name !== photoFileName));
    };

    const openPhotoPreview = (photoFile) => {
        setPreviewedPhotoUrl(photoFile.url);
    };
    const closePhotoPreview = () => {
        setPreviewedPhotoUrl(null);
    };


    return (
        <>
            {notificationContextHolder}

            <Upload
                listType='picture-card'
                accept='image/*'
                fileList={fileList}
                showUploadList={{ showRemoveIcon: isBeingEdited }}
                action={tryToUploadPhoto}
                customRequest={showUploadedPhoto}
                onPreview={openPhotoPreview}
                onRemove={removePhoto}
            >
                {isBeingEdited ? '+ Добавить фото' : null}
            </Upload>

            <Modal open={previewedPhotoUrl !== null} title={label} onCancel={closePhotoPreview} footer={null} width={900}>
                <img src={previewedPhotoUrl} alt={label} className='width--full-size' />
            </Modal>
        </>
    )
};


export default PhotoUpload;
