import { useEffect, useState } from 'react';
import { Alert, Divider, Input, Pagination, Skeleton, Space } from 'antd';

import * as CityService from '../../../core/services/city';
import * as SectionService from '../../../core/services/section';
import * as UserService from '../../../core/services/user';
import { capitalizeString } from '../../../core/utilities/callables';
import { minPageSize } from '../../../core/utilities/constants';

import Section from '../../../components/cards/Section';


const SectionsTab = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ sections, setSections ] = useState([]);
    const [ totalNumber, setTotalNumber ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ size, setSize ] = useState(minPageSize);
    const [ warehouseOptions, setWarehouseOptions ] = useState([]);
    const [ employeeOptions, setEmployeeOptions ] = useState([]);
    const [ searchQuery, setSearchQuery ] = useState('');

    const [ creationFormReRenderingBoolean, setCreationFormReRenderingBoolean ] = useState(false);

    const fetchSections = async (
        page,
        size,
        searchQuery = null,
    ) => {
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const [
                [ fetchedSections, totalNumber ],
                fetchedCitiesWithWarehouses,
                [ fetchedUsers ],
            ] = await Promise.all([
                SectionService.getSectionList(page, size, searchQuery),
                CityService.getCityListWithWarehouses(),
                UserService.getUserList(null, null),
            ]);

            setSections(fetchedSections);
            setTotalNumber(totalNumber);

            setWarehouseOptions(
                fetchedCitiesWithWarehouses.map((city) => ({
                    label: city.name,
                    options: city.warehouses.map((warehouse) => ({
                        label: warehouse.name,
                        value: warehouse.id,
                    })),
                })),
            );
            setEmployeeOptions(Object.values(
                fetchedUsers.reduce((roleGroup, user) => {
                    const userRole = capitalizeString(user.role);

                    if (!roleGroup[userRole]) {
                        roleGroup[userRole] = {
                            label: userRole,
                            options: [],
                        };
                    }

                    roleGroup[userRole].options.push({
                        label: user.fullname,
                        value: user.id,
                    });

                    return roleGroup;
                }, {}),
            ))
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const setPageAndSize = (page, size) => {
        setPage(page);
        setSize(size);
    };

    useEffect(() => {
        fetchSections(page, size);
    }, [ page, size ]);


    return (
        <Space direction='vertical' size='middle' className='width--full-size'>
            <Section
                key={creationFormReRenderingBoolean}
                title='Создание нового раздела'
                warehouseOptions={warehouseOptions}
                employeeOptions={employeeOptions}
                creationForm={true}
                onSubmit={() => {
                    setCreationFormReRenderingBoolean(!creationFormReRenderingBoolean);
                    fetchSections(page, size);
                }}
            />

            <Divider style={{ margin: '12px 0' }} />

            <Input.Search
                allowClear
                placeholder='Название раздела...'
                enterButton='Найти'
                loading={isLoading}
                value={searchQuery}
                onChange={(event) => {
                    setSearchQuery(event.target.value);
                }}
                onSearch={() => {
                    setPage(1);
                    fetchSections(page, size, searchQuery);
                }}
            />

            {
                isLoading ? <Skeleton active /> :
                errorMessage ? <Alert message={errorMessage} type='error' showIcon /> :
                totalNumber === '0' ? <Alert message='Разделы отсутствуют' type='info' showIcon /> : (
                    <>
                        {sections.map((section) => (
                            <Section
                                key={section.id}
                                section={section}
                                warehouseOptions={warehouseOptions}
                                employeeOptions={employeeOptions}
                                onSubmit={() => {
                                    setSearchQuery('');
                                    fetchSections(page, size);
                                }}
                            />
                        ))}

                        {totalNumber > minPageSize && (
                            <Pagination
                                showSizeChanger
                                total={totalNumber}
                                current={page}
                                pageSize={size}
                                onChange={setPageAndSize}
                            />
                        )}
                    </>
                )
            }
        </Space>
    );
};


export default SectionsTab;
