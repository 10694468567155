import { useEffect, useState } from 'react';


export const useWindowSize = () => {
    const [ windowSize, setWindowSize ] = useState(getWindowSize());

    const onWindowResize = () => {
        setWindowSize(getWindowSize());
    };

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);

    return windowSize;
};


const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
});
