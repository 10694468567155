import { useMemo, useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { CloseCircleOutlined, DeleteOutlined, EditOutlined, SaveFilled } from '@ant-design/icons';

import ButtonGroup from './ButtonGroup';


const CardButtonGroup = ({
    isCreationForm,
    isBeingEdited,
    isLoading,
    onSave,
    onEdit,
    onCancelEditing,
    onDelete,
    additionalButtons = [],
    additionalButtonsWhileEditing = [],
    additionalButtonsWhileNotEditing = [],
    style = {},
}) => {
    const [ isBeingConfirmed, setIsBeingConfirmed ] = useState(false);

    const openPopconfirm = () => {
        setIsBeingConfirmed(true);
    };
    const closePopconfirm = () => {
        setIsBeingConfirmed(false);
    };

    const assembledAdditionalButtons = useMemo(() => (
        additionalButtons.filter((button) => !!button).map((button, buttonIndex) => (
            <Button
                key={buttonIndex}
                icon={button.icon}
                onClick={button.onClick}
                className='width--full-size text--left'
                style={button.style}
            >
                {button.title}
            </Button>
        ))
    ), [ additionalButtons ]);

    const assembledAdditionalButtonsWhileEditing = useMemo(() => (
        additionalButtonsWhileEditing.filter((button) => !!button).map((button, buttonIndex) => (
            <Button
                key={buttonIndex}
                icon={button.icon}
                onClick={button.onClick}
                className='width--full-size text--left'
            >
                {button.title}
            </Button>
        ))
    ), [ additionalButtonsWhileEditing ]);

    const assembledAdditionalButtonsWhileNotEditing = useMemo(() => (
        additionalButtonsWhileNotEditing.filter((button) => !!button).map((button, buttonIndex) => (
            <Button
                key={buttonIndex}
                icon={button.icon}
                onClick={button.onClick}
                className='width--full-size text--left'
            >
                {button.title}
            </Button>
        ))
    ), [ additionalButtonsWhileNotEditing ]);


    return (
        <ButtonGroup style={style}>
            {assembledAdditionalButtons}

            {isBeingEdited ? (
                <>
                    {assembledAdditionalButtonsWhileEditing}

                    {onSave && (
                        <Button
                            type='primary'
                            icon={<SaveFilled />}
                            loading={isLoading}
                            onClick={onSave}
                            className='width--full-size text--left'
                        >
                            Сохранить
                        </Button>
                    )}

                    {onCancelEditing && !isCreationForm && (
                        <Button
                            icon={<CloseCircleOutlined />}
                            onClick={onCancelEditing}
                            className='width--full-size text--left'
                        >
                            Отменить
                        </Button>
                    )}
                </>
            ) : (
                <>
                    {assembledAdditionalButtonsWhileNotEditing}

                    {onEdit && (
                        <Button
                            icon={<EditOutlined />}
                            onClick={onEdit}
                            className='width--full-size text--left'
                        >
                            Изменить
                        </Button>
                    )}

                    {onDelete && (
                        <Popconfirm
                            icon={null}
                            title='Подтвердите действие'
                            description='Вы уверены, что хотите удалить запись?'
                            open={isBeingConfirmed}
                            okButtonProps={{ size: 'middle' }}
                            cancelButtonProps={{ size: 'middle' }}
                            okText='Да'
                            cancelText='Нет, отменить'
                            onConfirm={() => {
                                closePopconfirm();
                                onDelete();
                            }}
                            onCancel={closePopconfirm}
                        >
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                loading={isLoading}
                                onClick={openPopconfirm}
                                className='width--full-size text--left'
                            >
                                Удалить
                            </Button>
                        </Popconfirm>
                    )}
                </>
            )}
        </ButtonGroup>
    );
};


export default CardButtonGroup;
