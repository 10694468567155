import { useMemo, useState } from 'react';
import { Button, DatePicker, Form, Grid, Input, InputNumber, Space, Switch } from 'antd';
import ruLocale from 'antd/es/date-picker/locale/ru_RU';
import { CheckOutlined, CloseOutlined, DeleteOutlined, ExportOutlined } from '@ant-design/icons';

import { formatDateTime } from '../../core/utilities/callables';
import { IndicatorDataType } from '../../core/utilities/constants';

import FilePathsFormList from './FilePathsFormList';
import PhotoUpload from '../PhotoUpload';


const makeInitialValueForIndicatorDataType = (
    indicatorDataType,
    initialRawValue,
) => {
    switch (indicatorDataType) {
        case IndicatorDataType.boolean.value:
            return initialRawValue || false;

        default:
            return initialRawValue;
    }
};


const EntryFormItem = ({
    name,
    label,
    dataType,
    initialRawValue = null,
    initialFileList = null,
    initialFilePaths = null,
    initialComment = null,
    initialRemark = null,
    initialPhotoFileList = null,
    initialPhotoFilePaths = null,
    createdAt,
    updatedAt,
    isBeingEdited = true,
    onDelete,
    onRestore,
    replaceDeleteByRestore = false,
}) => {
    const initialValue = useMemo(
        () => makeInitialValueForIndicatorDataType(dataType, initialRawValue),
        [ dataType, initialRawValue ],
    );

    const [ isDatePickerOpen, setIsDatePickerOpen ] = useState(false);
    const [ shouldHideRemarkField, setShouldShowRemarkField ] = useState(dataType === IndicatorDataType.boolean.value && initialValue);

    const viewportSize = Grid.useBreakpoint();

    const onDatePickerOpenChange = (isOpen) => {
        setIsDatePickerOpen(isBeingEdited && isOpen);
    };

    const tooltipLines = useMemo(() => (
        [
            { visibilityCondition: !!createdAt, line: `Добавление от ${formatDateTime(createdAt)}` },
            { visibilityCondition: createdAt && updatedAt, line: `Изменение от ${formatDateTime(updatedAt)}` },
        ].filter((({ visibilityCondition }) => visibilityCondition))
    ), [ createdAt, updatedAt ]);
    const tooltip = useMemo(() => (
        tooltipLines.length > 0 && (
            <>
                {tooltipLines.map(({ line }, index) => (
                    <p key={index}>{line}</p>
                ))}
            </>
        )
    ), [ tooltipLines ]);

    const isMainFormItemVisible = useMemo(() => (
        dataType !== IndicatorDataType.photos.value || (dataType === IndicatorDataType.photos.value && (isBeingEdited || initialFileList.length > 0))
    ), [ dataType, isBeingEdited, initialFileList ]);
    const isFilePathsFormListVisible = useMemo(() => (
        dataType === IndicatorDataType.photos.value && (isBeingEdited || (initialFilePaths ?? []).length > 0)
    ), [ dataType, isBeingEdited, initialFilePaths ]);

    const isPhotosFormItemVisible = useMemo(() => (
        isBeingEdited || (initialPhotoFileList ?? []).length > 0
    ), [ isBeingEdited, initialPhotoFileList ]);
    const isPhotoFilePathsFormListVisible = useMemo(() => (
        isBeingEdited || (initialPhotoFilePaths ?? []).length > 0
    ), [ isBeingEdited, initialPhotoFilePaths ]);


    if (!Array.isArray(name)) {
        name = [name];
    }


    return (
        <>
            {isMainFormItemVisible && (
                <Form.Item
                    name={[ ...name, 'value' ]}
                    label={label}
                    tooltip={tooltip}
                    initialValue={initialValue}
                    valuePropName={dataType === IndicatorDataType.boolean.value ? 'checked' : 'value'}
                    style={{ marginBottom: 8 }}
                >
                    {{
                        [IndicatorDataType.string.value]: (
                            <Input.TextArea
                                autoSize
                                readOnly={!isBeingEdited}
                                placeholder='Введите текст...'
                                className='width--full-size'
                            />
                        ),
                        [IndicatorDataType.integer.value]: (
                            <InputNumber
                                readOnly={!isBeingEdited}
                                placeholder='Введите целое число...'
                                className='width--full-size'
                            />
                        ),
                        [IndicatorDataType.float.value]: (
                            <InputNumber
                                readOnly={!isBeingEdited}
                                placeholder='Введите вещественное число...'
                                step={0.01}
                                className='width--full-size'
                            />
                        ),
                        [IndicatorDataType.boolean.value]: (
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                disabled={!isBeingEdited}
                                style={{ width: 60 }}
                                onChange={setShouldShowRemarkField}
                            />
                        ),
                        [IndicatorDataType.date.value]: (
                            <DatePicker
                                inputReadOnly={!isBeingEdited}
                                open={isBeingEdited && isDatePickerOpen}
                                onOpenChange={onDatePickerOpenChange}
                                locale={ruLocale}
                                format='DD.MM.YYYY'
                                allowClear={false}
                                placeholder='Выберите дату...'
                                className='width--full-size'
                            />
                        ),
                        [IndicatorDataType.photos.value]: (
                            <PhotoUpload
                                name={[ ...name, 'value' ]}
                                label={label}
                                initialFileList={initialFileList}
                                isBeingEdited={isBeingEdited}
                            />
                        ),
                    }[dataType]}
                </Form.Item>
            )}

            {dataType === IndicatorDataType.boolean.value && (
                <Space
                    direction='vertical'
                    align='start'
                    size={8}
                    className='width--full-size'
                    style={{ marginBottom: 8 }}
                >
                    <Space
                        direction={viewportSize.lg ? 'horizontal' : 'vertical'}
                        align='start'
                        size={[16, 8]}
                        className='width--full-size'
                    >
                        <Form.Item
                            name={[ ...name, 'comment' ]}
                            label={<span className='text--hint'>Комментарий</span>}
                            initialValue={initialComment}
                            tooltip='Общая информация по данному вопросу'
                            style={{ marginBottom: 0 }}
                        >
                            <Input.TextArea
                                autoSize
                                maxLength={2000}
                                readOnly={!isBeingEdited}
                                placeholder='Введите комментарий...'
                            />
                        </Form.Item>

                        {!shouldHideRemarkField && (
                            <Form.Item
                                name={[ ...name, 'remark' ]}
                                label={<span className='text--hint'>Примечание</span>}
                                initialValue={initialRemark}
                                tooltip='Причина выбора отрицательного варианта ответа'
                                style={{ marginBottom: 0 }}
                            >
                                <Input.TextArea
                                    autoSize
                                    maxLength={2000}
                                    readOnly={!isBeingEdited}
                                    placeholder='Введите примечание...'
                                />
                            </Form.Item>
                        )}
                    </Space>

                    <Space
                        direction='vertical'
                        align='start'
                        size={8}
                        className='width--full-size'
                    >
                        {isPhotosFormItemVisible && (
                            <Form.Item
                                name={[ ...name, 'photos' ]}
                                label={<span className='text--hint'>Фото</span>}
                                initialValue={initialPhotoFileList}
                                style={{ marginBottom: 0 }}
                            >
                                <PhotoUpload
                                    name={[ ...name, 'photos' ]}
                                    label='Фото'
                                    initialFileList={initialPhotoFileList}
                                    isBeingEdited={isBeingEdited}
                                />
                            </Form.Item>
                        )}

                        {isPhotoFilePathsFormListVisible && (
                            <FilePathsFormList
                                name={[ ...name, 'photo-file-paths' ]}
                                label={!isPhotosFormItemVisible && <span className='text--hint'>Фото</span>}
                                initialValue={initialPhotoFilePaths}
                                isBeingEdited={isBeingEdited}
                            />
                        )}
                    </Space>
                </Space>
            )}

            {isFilePathsFormListVisible && (
                <FilePathsFormList
                    name={[ ...name, 'file-paths' ]}
                    initialValue={initialFilePaths}
                    isBeingEdited={isBeingEdited}
                    label={!isMainFormItemVisible && label}
                    tooltip={!isMainFormItemVisible && tooltip}
                />
            )}

            {isBeingEdited && (
                <div
                    className='width--full-size'
                    style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}
                >
                    {replaceDeleteByRestore ? (
                        <Button icon={<ExportOutlined />} onClick={onRestore}>
                            Восстановить
                        </Button>
                    ) : (
                        <Button icon={<DeleteOutlined />} danger onClick={onDelete}>
                            Удалить
                        </Button>
                    )}
                </div>
            )}
        </>
    );
};


export default EntryFormItem;
