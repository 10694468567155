import axios from 'axios';


export const apiUrl = process.env.REACT_APP_API_URL || '/api';


const api = axios.create({
    baseURL: apiUrl,
});


export default api;
