import { useEffect, useState } from 'react';
import { Alert, Divider, Input, Pagination, Skeleton, Space } from 'antd';

import * as CityService from '../../../core/services/city';
import * as UserService from '../../../core/services/user';
import { capitalizeString } from '../../../core/utilities/callables';
import { minPageSize } from '../../../core/utilities/constants';

import User from '../../../components/cards/User';


const UsersTab = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ users, setUsers ] = useState([]);
    const [ totalNumber, setTotalNumber ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ size, setSize ] = useState(minPageSize);
    const [ userRoleOptions, setUserRoleOptions ] = useState([]);
    const [ warehouseOptions, setWarehouseOptions ] = useState([]);
    const [ searchQuery, setSearchQuery ] = useState('');

    const [ creationFormReRenderingBoolean, setCreationFormReRenderingBoolean ] = useState(false);

    const fetchUsers = async (
        page,
        size,
        searchQuery = null,
    ) => {
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const [
                [ fetchedUsers, totalNumber ],
                fetchedUserRoles,
                fetchedCitiesWithWarehouses,
            ] = await Promise.all([
                UserService.getUserList(page, size, searchQuery),
                UserService.getUserRoleList(),
                CityService.getCityListWithWarehouses(),
            ]);

            setUsers(fetchedUsers);
            setTotalNumber(totalNumber);

            setUserRoleOptions(
                fetchedUserRoles.map((role) => ({
                    label: capitalizeString(role),
                    value: role,
                })),
            );
            setWarehouseOptions(
                fetchedCitiesWithWarehouses.map((city) => ({
                    label: city.name,
                    options: city.warehouses.map((warehouse) => ({
                        label: warehouse.name,
                        value: warehouse.id,
                    })),
                })),
            );
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const setPageAndSize = (page, size) => {
        setPage(page);
        setSize(size);
    };

    useEffect(() => {
        fetchUsers(page, size);
    }, [ page, size ]);


    return (
        <Space direction='vertical' size='middle' className='width--full-size'>
            <User
                key={creationFormReRenderingBoolean}
                title='Создание нового пользователя'
                roleOptions={userRoleOptions}
                warehouseOptions={warehouseOptions}
                creationForm={true}
                onSubmit={() => {
                    setCreationFormReRenderingBoolean(!creationFormReRenderingBoolean);
                    fetchUsers(page, size);
                }}
            />

            <Divider style={{ margin: '12px 0' }} />

            <Input.Search
                allowClear
                placeholder='Имя пользователя...'
                enterButton='Найти'
                loading={isLoading}
                value={searchQuery}
                onChange={(event) => {
                    setSearchQuery(event.target.value);
                }}
                onSearch={() => {
                    setPage(1);
                    fetchUsers(page, size, searchQuery);
                }}
            />

            {
                isLoading ? <Skeleton active /> :
                errorMessage ? <Alert message={errorMessage} type='error' showIcon /> :
                totalNumber === '0' ? <Alert message='Пользователи отсутствуют' type='info' showIcon /> : (
                    <>
                        {users.map((user) => (
                            <User
                                key={user.id}
                                user={user}
                                roleOptions={userRoleOptions}
                                warehouseOptions={warehouseOptions}
                                onSubmit={() => {
                                    setSearchQuery('');
                                    fetchUsers(page, size);
                                }}
                            />
                        ))}

                        {totalNumber > minPageSize && (
                            <Pagination
                                showSizeChanger
                                total={totalNumber}
                                current={page}
                                pageSize={size}
                                onChange={setPageAndSize}
                            />
                        )}
                    </>
                )
            }
        </Space>
    );
};


export default UsersTab;
