import { useEffect, useState } from 'react';
import { Alert, Button, Card, Divider, Dropdown, Grid, Space } from 'antd';
import { AppstoreAddOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import * as AttachmentService from '../../core/services/attachment';
import { IndicatorDataType, attachmentNamePattern, shareDrivePathOrAnyHttpLinkPattern } from '../../core/utilities/constants';

import ButtonGroup from '../ButtonGroup';
import EntryFormItem from '../formEntities/EntryFormItem';


const formatEntryValueForInput = (
    entryDataType,
    entryRawValue,
) => {
    switch (entryDataType) {
        case IndicatorDataType.boolean.value:
            return entryRawValue.toString().toLowerCase() === 'true';
        case IndicatorDataType.date.value:
            return dayjs(entryRawValue);

        default:
            return entryRawValue;
    }
};

const formatEntryFileListForInput = (rawFiles) => {
    if (!rawFiles) { return null; }

    return JSON.parse(rawFiles).filter((photoFileName) => (
        photoFileName?.search(attachmentNamePattern) === 0
    )).map((photoFileName) => ({
        status: 'done',
        name: photoFileName,
        url: AttachmentService.attachmentGetterApiUrl + photoFileName,
    }));
};

const formatEntryFilePathsForInput = (rawFiles) => {
    if (!rawFiles) { return null; }

    return JSON.parse(rawFiles).filter((filePathName) => (
        filePathName?.search(shareDrivePathOrAnyHttpLinkPattern) === 0
    ));
};


const SubsectionWithEntriesFormItems = ({
    name,
    subsection,
    isBeingEdited,
    onEdit,
    onChangeEntriesToCreate,
    onChangeEntryIdsToDelete,
}) => {
    const viewportSize = Grid.useBreakpoint();

    const [ indicators, setIndicators ] = useState(subsection.indicators);
    const [ entriesToUpdate ] = useState(subsection.entries);
    const [ entriesToCreate, setEntriesToCreate ] = useState([]);
    const [ entryIdsToDelete, setEntryIdsToDelete ] = useState([]);

    const generateNextPseudoId = () => {
        return (entriesToCreate[entriesToCreate.length - 1]?.pseudoId ?? -1) + 1;
    };

    const addAllEntriesToCreate = () => {
        const newEntriesToCreate = indicators.map((indicator, indicatorIndex) => ({
            pseudoId: generateNextPseudoId() + indicatorIndex,
            name: indicator.name,
            data_type: indicator.data_type,
        }));

        setEntriesToCreate(newEntriesToCreate);
        onChangeEntriesToCreate(newEntriesToCreate);
    };
    const addEntryToCreate = (indicator) => {
        const newEntriesToCreate = [
            ...entriesToCreate,
            {
                pseudoId: generateNextPseudoId(),
                name: indicator.name,
                data_type: indicator.data_type,
            },
        ];

        setEntriesToCreate(newEntriesToCreate);
        onChangeEntriesToCreate(newEntriesToCreate);
    };
    const removeEntryToCreate = (entryPseudoId) => {
        const newEntriesToCreate = entriesToCreate.filter((entry) => entry.pseudoId !== entryPseudoId);

        setEntriesToCreate(newEntriesToCreate);
        onChangeEntriesToCreate(newEntriesToCreate);
    };

    const addEntryToDelete = (entryId) => {
        const newEntryIdsToDelete = [
            ...entryIdsToDelete,
            entryId,
        ];

        setEntryIdsToDelete(newEntryIdsToDelete);
        onChangeEntryIdsToDelete(newEntryIdsToDelete);
    };
    const removeEntryToDelete = (markedEntryId) => {
        const newEntryIdsToDelete = entryIdsToDelete.filter((entryId) => entryId !== markedEntryId);

        setEntryIdsToDelete(newEntryIdsToDelete);
        onChangeEntryIdsToDelete(newEntryIdsToDelete);
    };


    useEffect(() => {
        setIndicators(
            subsection.indicators.filter((indicator) => (
                !entriesToUpdate.find((entry) => entry.name === indicator.name && entry.data_type === indicator.data_type) &&
                !entriesToCreate.find((entry) => entry.name === indicator.name && entry.data_type === indicator.data_type)
            )),
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ entriesToUpdate, entriesToCreate ]);


    return (
        <Card title={subsection.name} type='inner' size='small' style={{ marginBottom: 8 }}>
            <Space direction='vertical' size='middle' className='width--full-size'>
                {entriesToUpdate.length === 0 && entriesToCreate.length === 0 ? (
                    <Alert message='Записи отсутствуют' type='info' showIcon />
                ) : (
                    <>
                        {entriesToUpdate.map((entry) => (
                            <EntryFormItem
                                key={entry.id}
                                name={[ name, `entry-to-update-${entry.id}` ]}
                                label={entry.name}
                                dataType={entry.data_type}
                                initialRawValue={formatEntryValueForInput(entry.data_type, entry.raw_value)}
                                initialFileList={entry.data_type === IndicatorDataType.photos.value && formatEntryFileListForInput(entry.raw_value)}
                                initialFilePaths={entry.data_type === IndicatorDataType.photos.value && formatEntryFilePathsForInput(entry.raw_value)}
                                initialComment={entry.comment}
                                initialRemark={entry.remark}
                                initialPhotoFileList={formatEntryFileListForInput(entry.photos)}
                                initialPhotoFilePaths={formatEntryFilePathsForInput(entry.photos)}
                                createdAt={entry.created_at}
                                updatedAt={entry.updated_at}
                                isBeingEdited={isBeingEdited}
                                replaceDeleteByRestore={entryIdsToDelete.indexOf(entry.id) !== -1}
                                onDelete={() => { addEntryToDelete(entry.id); }}
                                onRestore={() => { removeEntryToDelete(entry.id); }}
                            />
                        ))}

                        {entriesToUpdate.length > 0 && entriesToCreate.length > 0 && <Divider />}

                        {entriesToCreate.map((entry) => (
                            <EntryFormItem
                                key={entry.pseudoId}
                                name={[ name, `entry-to-create-${entry.pseudoId}` ]}
                                label={entry.name}
                                dataType={entry.data_type}
                                onDelete={() => { removeEntryToCreate(entry.pseudoId); }}
                            />
                        ))}
                    </>
                )}
            </Space>

            {indicators.length > 0 && (
                <ButtonGroup>
                    <Dropdown trigger={['click']} overlayStyle={!viewportSize.lg && { width: 0 }} menu={{
                        items: [ 
                            {
                                key: 0,
                                label: (
                                    <div>
                                        Добавить все показатели
                                        <Divider style={{ marginTop: 12, marginBottom: 0 }} />
                                    </div>
                                ),
                                onClick: () => {
                                    onEdit();
                                    addAllEntriesToCreate();
                                },
                            },
                            ...indicators.map((indicator) => ({
                                key: indicator.id,
                                label: indicator.name,
                                onClick: () => {
                                    onEdit();
                                    addEntryToCreate(indicator);
                                },
                            })),
                        ],
                    }}>
                        <Button type='primary' icon={<AppstoreAddOutlined />} className='width--full-size text--left'>
                            Добавить запись
                        </Button>
                    </Dropdown>
                </ButtonGroup>
            )}
        </Card>
    );
};


export default SubsectionWithEntriesFormItems;
