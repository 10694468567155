import { useEffect, useState } from 'react';
import { Alert, Divider, Grid, Input, Pagination, Segmented, Select, Skeleton, Space } from 'antd';
import { GlobalOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';

import * as CityService from '../../../core/services/city';
import * as TaskService from '../../../core/services/task';
import * as UserService from '../../../core/services/user';
import { capitalizeString } from '../../../core/utilities/callables';
import { TaskAssignee, TaskStatus, minPageSize } from '../../../core/utilities/constants';

import Task from '../../../components/cards/Task';
import { filterOption } from '../../../components/utilities/callables';


const TasksTab = () => {
    const viewportSize = Grid.useBreakpoint();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ tasks, setTasks ] = useState([]);
    const [ totalNumber, setTotalNumber ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ size, setSize ] = useState(minPageSize);
    const [ taskPriorityOptions, setTaskPriorityOptions ] = useState([]);
    const [ taskPeriodicityOptions, setTaskPeriodicityOptions ] = useState([]);
    const [ warehouseOptions, setWarehouseOptions ] = useState([]);
    const [ employeeOptions, setEmployeeOptions ] = useState([]);
    const [ searchQuery, setSearchQuery ] = useState('');
    const [ assignee, setAssignee ] = useState(null);
    const [ assigneeId, setAssigneeId ] = useState(null);
    const [ taskStatus, setTaskStatus ] = useState(TaskStatus.all);

    const [ templateTask, setTemplateTask ] = useState(null);
    const [ creationFormReRenderingBoolean, setCreationFormReRenderingBoolean ] = useState(false);

    const changeAssignee = (newAssignee) => {
        setAssignee(newAssignee);
        setAssigneeId(null);
    };

    const fetchTasks = async (
        assignee,
        assigneeId,
        taskStatus,
        page,
        size,
        searchQuery = null,
    ) => {
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const [
                [ fetchedTasks, totalNumber ],
                fetchedTaskPriority,
                fetchedTaskPeriodicity,
                fetchedCitiesWithWarehouses,
                [ fetchedUsers ],
            ] = await Promise.all([
                TaskService.getTaskList(
                    assignee && assignee.toLowerCase(),
                    assigneeId,
                    taskStatus || null,
                    page,
                    size,
                    searchQuery,
                ),
                TaskService.getTaskPriorityList(),
                TaskService.getTaskPeriodicityList(),
                CityService.getCityListWithWarehouses(),
                UserService.getUserList(null, null),
            ]);

            setTasks(fetchedTasks);
            setTotalNumber(totalNumber);

            setTaskPriorityOptions(
                fetchedTaskPriority.map((priority) => ({
                    label: capitalizeString(priority),
                    value: priority,
                })),
            );
            setTaskPeriodicityOptions([
                { label: 'Не повторять', value: '' },
                ...fetchedTaskPeriodicity.map((periodicity) => ({
                    label: capitalizeString(periodicity),
                    value: periodicity,
                })),
            ]);
            setWarehouseOptions(
                fetchedCitiesWithWarehouses.map((city) => ({
                    label: city.name,
                    options: city.warehouses.map((warehouse) => ({
                        label: warehouse.name,
                        value: warehouse.id,
                    })),
                })),
            );
            setEmployeeOptions(Object.values(
                fetchedUsers.reduce((roleGroup, user) => {
                    const userRole = capitalizeString(user.role);

                    if (!roleGroup[userRole]) {
                        roleGroup[userRole] = {
                            label: userRole,
                            options: [],
                        };
                    }

                    roleGroup[userRole].options.push({
                        label: user.fullname,
                        value: user.id,
                    });

                    return roleGroup;
                }, {}),
            ))
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const setPageAndSize = (page, size) => {
        setPage(page);
        setSize(size);
    };

    useEffect(() => {
        fetchTasks(assignee, assigneeId, taskStatus, page, size);
    }, [ assignee, assigneeId, taskStatus, page, size ]);


    return (
        <Space direction='vertical' size='middle' className='width--full-size'>
            <Task
                key={`task-${templateTask?.id}-${creationFormReRenderingBoolean}`}
                creationForm
                title='Создание новой задачи'
                task={templateTask}
                priorityOptions={taskPriorityOptions}
                periodicityOptions={taskPeriodicityOptions}
                warehouseOptions={warehouseOptions}
                employeeOptions={employeeOptions}
                onSubmit={() => {
                    setTemplateTask(null);
                    setCreationFormReRenderingBoolean(!creationFormReRenderingBoolean);

                    fetchTasks(assignee, assigneeId, taskStatus, page, size);
                }}
            />

            <Divider style={{ margin: '12px 0' }} />

            <Input.Search
                allowClear
                placeholder='Название задачи...'
                enterButton='Найти'
                loading={isLoading}
                value={searchQuery}
                onChange={(event) => {
                    setSearchQuery(event.target.value);
                }}
                onSearch={() => {
                    setPage(1);
                    fetchTasks(assignee, assigneeId, taskStatus, page, size, searchQuery);
                }}
            />

            <Space direction='vertical' className='width--full-size'>
                <Segmented
                    block={!viewportSize.lg}
                    value={assignee}
                    onChange={changeAssignee}
                    options={[
                        { label: 'Все', value: null, icon: <GlobalOutlined /> },
                        { label: TaskAssignee.warehouse, value: TaskAssignee.warehouse, icon: <ShopOutlined /> },
                        { label: TaskAssignee.employee, value: TaskAssignee.employee, icon: <UserOutlined /> },
                    ]}
                />

                {assignee !== null && (
                    <Select
                        virtual={false}
                        showSearch
                        allowClear
                        placeholder='Найти исполнителя...'
                        optionFilterProp='children'
                        value={assigneeId}
                        onChange={setAssigneeId}
                        filterOption={filterOption}
                        options={{
                            [TaskAssignee.warehouse]: warehouseOptions,
                            [TaskAssignee.employee]: employeeOptions,
                        }[assignee]}
                        className='width--full-size'
                    />
                )}

                <Select
                    virtual={false}
                    value={taskStatus}
                    onChange={setTaskStatus}
                    options={[
                        { label: 'Все', value: TaskStatus.all },
                        { label: 'В работе', value: TaskStatus.inProgress },
                        { label: 'Просроченные', value: TaskStatus.overdue },
                        { label: 'Завершённые', value: TaskStatus.completed },
                    ]}
                    className='width--full-size'
                />
            </Space>

            {
                isLoading ? <Skeleton active /> :
                errorMessage ? <Alert message={errorMessage} type='error' showIcon /> :
                totalNumber === '0' ? <Alert message='Задачи отсутствуют' type='info' showIcon /> : (
                    <>
                        {tasks.map((task) => (
                            <Task
                                key={task.id}
                                title={task.name}
                                task={task}
                                priorityOptions={taskPriorityOptions}
                                periodicityOptions={taskPeriodicityOptions}
                                warehouseOptions={warehouseOptions}
                                employeeOptions={employeeOptions}
                                onSubmit={() => {
                                    setSearchQuery('');
                                    fetchTasks(assignee, assigneeId, taskStatus, page, size);
                                }}
                                onUseAsTemplate={() => {
                                    setTemplateTask({ ...task });
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }}
                            />
                        ))}

                        {totalNumber > minPageSize && (
                            <Pagination
                                showSizeChanger
                                total={totalNumber}
                                current={page}
                                pageSize={size}
                                onChange={setPageAndSize}
                            />
                        )}
                    </>
                )
            }
        </Space>
    );
};


export default TasksTab;
