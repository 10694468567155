import { useEffect, useState } from 'react';
import { Alert, Pagination, Skeleton, Space } from 'antd';

import { minPageSize } from '../../../core/utilities/constants';


const TasksTab = ({
    taskStatus,
    getTaskList,
    Task,
}) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ tasks, setTasks ] = useState([]);
    const [ totalNumber, setTotalNumber ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ size, setSize ] = useState(minPageSize);

    const fetchTasks = async (taskStatus, page, size) => {
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const [ fetchedTasks, totalNumber ] = await getTaskList(taskStatus, page, size);

            setTasks(fetchedTasks);
            setTotalNumber(totalNumber);
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const setPageAndSize = (page, size) => {
        setPage(page);
        setSize(size);
    };

    useEffect(() => {
        setPageAndSize(1, minPageSize);
    }, [ taskStatus, getTaskList, Task ]);

    useEffect(() => {
        fetchTasks(taskStatus, page, size);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ taskStatus, page, size, getTaskList, Task ]);


    if (isLoading) { return <Skeleton active />; }
    if (errorMessage) { return <Alert message={errorMessage} type='error' showIcon />; }
    if (totalNumber === '0') { return <Alert message='Задачи отсутствуют' type='info' showIcon />; }

    return (
        <Space direction='vertical' size='middle' className='width--full-size'>
            {tasks.map((task) => (
                <Task
                    key={`${task.id}-${task.completion_id}`}
                    task={task}
                    onUpdate={() => { fetchTasks(taskStatus, page, size); }}
                />
            ))}

            {totalNumber > minPageSize && (
                <Pagination showSizeChanger total={totalNumber} current={page} pageSize={size} onChange={setPageAndSize} />
            )}
        </Space>
    );
};


export default TasksTab;
