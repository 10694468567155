import { useState } from 'react';
import { Tabs } from 'antd';

import * as TaskService from '../../core/services/task';
import { TaskStatus } from '../../core/utilities/constants';

import TaskForEmployee from '../../components/cards/TaskForEmployee';
import TitledLayout from '../../components/layouts/TitledLayout';

import TasksTab from './tabs/TasksTab';

import { changeTaskStatusTab } from './utilities/callables';
import { taskStatusTabs } from './utilities/constants';


const TasksRegularPage = () => {
    const [ tasksStatus, setTasksStatus ] = useState(TaskStatus.inProgress);
    const [ currentTasksTabKey, setCurrentTasksTabKey ] = useState(taskStatusTabs[0].key);


    return (
        <TitledLayout title='Мои задачи'>
            <Tabs
                activeKey={currentTasksTabKey}
                items={taskStatusTabs}
                onChange={(tabKey) => {
                    changeTaskStatusTab(
                        tabKey,
                        setCurrentTasksTabKey,
                        setTasksStatus,
                    );
                }}
            />

            <TasksTab
                taskStatus={tasksStatus}
                getTaskList={TaskService.getTaskListForEmployee}
                Task={TaskForEmployee}
            />
        </TitledLayout>
    );
};


export default TasksRegularPage;
