import { useState } from 'react';
import { Button, Card, Descriptions, Grid, Popconfirm, Space, Tag, Upload, notification } from 'antd';
import { CheckCircleFilled, ClockCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import * as AttachmentService from '../../core/services/attachment';
import * as TaskService from '../../core/services/task';
import { capitalizeString } from '../../core/utilities/callables';

import ButtonGroup from '../ButtonGroup';


const TaskForEmployee = ({
    task,
    onUpdate,
}) => {
    const viewportSize = Grid.useBreakpoint();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ notificator, notificationContextHolder ] = notification.useNotification();
    const [ isBeingConfirmed, setIsBeingConfirmed ] = useState(false);

    const openPopconfirm = () => {
        setIsBeingConfirmed(true);
    };
    const closePopconfirm = () => {
        setIsBeingConfirmed(false);
    };

    const toggleTask = async () => {
        setIsLoading(true);

        try {
            await TaskService.toggleTaskCompletion(task.completion_id);

            onUpdate();
        } catch (error) {
            notificator.error({
                message: 'Ошибка обновления задачи',
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const getAssigneeName = (warehouse, employee) => {
        if (warehouse === null) { return 'меня'; }

        return `мой склад «${task.warehouse}»`
    };

    return (
        <>
            {notificationContextHolder}

            <Card title={task.name}>
                <Space direction='vertical' size='middle' className='width--full-size'>
                    <Tag
                        color='blue'
                        icon={task.warehouse === null ? <UserOutlined /> : <ShopOutlined />}
                        style={{ display: 'block', margin: 0, whiteSpace: 'break-spaces' }}
                    >
                        назначена на {getAssigneeName(task.warehouse)}
                    </Tag>

                    <Descriptions layout={viewportSize.lg ? 'vertical' : 'horizontal'} size='small'>
                        <Descriptions.Item label='Автор'>
                            {task.author}
                        </Descriptions.Item>

                        <Descriptions.Item label='Срок'>
                            {dayjs(task.deadline).format('DD.MM.YYYY')}
                        </Descriptions.Item>

                        <Descriptions.Item label='Приоритет'>
                            <Tag
                                icon={{
                                    'обычная': null,
                                    'срочная': <ClockCircleOutlined />,
                                    'критическая': <ExclamationCircleOutlined />
                                }[task.priority]}
                                color={{
                                    'обычная': 'default',
                                    'срочная': 'warning',
                                    'критическая': 'error',
                                }[task.priority]}
                            >
                                {capitalizeString(task.priority)}
                            </Tag>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions layout='vertical' size='small'>
                        {task.completed_at !== null && (
                            <Descriptions.Item label='Завершено'>
                                {dayjs(task.completed_at).format('DD.MM.YYYY в HH:mm:ss')}
                            </Descriptions.Item>
                        )}

                        {task.attachments.length !== 0 && (
                            <Descriptions.Item label='Файлы' span={2} style={{ overflow: 'hidden' }}>
                                <Upload
                                    fileList={task.attachments.map((attachmentName) => ({
                                        status: 'done',
                                        name: attachmentName,
                                        url: AttachmentService.attachmentGetterApiUrl + attachmentName,
                                    }))}
                                    showUploadList={{ showRemoveIcon: false }}
                                />
                            </Descriptions.Item>
                        )}
                    </Descriptions>

                    <Descriptions layout='vertical' size='small'>
                        {task.description !== null && (
                            <Descriptions.Item label='Описание' span={3}>
                                {task.description}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Space>

                <ButtonGroup>
                    <Popconfirm
                        icon={null}
                        title='Подтвердите действие'
                        description={`Вы уверены, что хотите ${task.completed_at === null ? 'завершить задачу' : 'вернуть задачу в работу'}?`}
                        open={isBeingConfirmed}
                        okButtonProps={{ size: 'middle' }}
                        cancelButtonProps={{ size: 'middle' }}
                        okText='Да'
                        cancelText='Нет, отменить'
                        onConfirm={() => {
                            closePopconfirm();
                            toggleTask();
                        }}
                        onCancel={closePopconfirm}
                    >
                        <Button
                            type={task.completed_at === null ? 'primary' : 'default'}
                            icon={task.completed_at === null ? <CheckCircleFilled /> : <CloseCircleOutlined />}
                            loading={isLoading}
                            onClick={openPopconfirm}
                            className='width--full-size text--left'
                        >
                            {task.completed_at === null ? 'Завершить' : 'Вернуть в работу'}
                        </Button>
                    </Popconfirm>
                </ButtonGroup>
            </Card>
        </>
    );
};


export default TaskForEmployee;
