import { useEffect, useState } from 'react';
import { Alert, Divider, Input, Pagination, Skeleton, Space } from 'antd';

import * as BackgroundJobService from '../../../core/services/backgroundJob';
import { capitalizeString } from '../../../core/utilities/callables';
import { minPageSize } from '../../../core/utilities/constants';

import BackgroundJob from '../../../components/cards/BackgroundJob';


const BackgroundJobsTab = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ backgroundJobs, setBackgroundJobs ] = useState([]);
    const [ totalNumber, setTotalNumber ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ size, setSize ] = useState(minPageSize);
    const [ backgroundJobIdOptions, setBackgroundJobIdOptions ] = useState([]);
    const [ searchQuery, setSearchQuery ] = useState('');

    const [ creationFormReRenderingBoolean, setCreationFormReRenderingBoolean ] = useState(false);

    const fetchBackgroundJobs = async (
        page,
        size,
        searchQuery = null,
    ) => {
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const [
                [ fetchedBackgroundJobs, totalNumber ],
                fetchedBackgroundJobIds,
            ] = await Promise.all([
                BackgroundJobService.getBackgroundJobList(page, size, searchQuery),
                BackgroundJobService.getBackgroundJobIdList(),
            ]);

            setBackgroundJobs(fetchedBackgroundJobs);
            setTotalNumber(totalNumber);

            setBackgroundJobIdOptions(
                fetchedBackgroundJobIds.map((backgroundJobId) => ({
                    label: capitalizeString(backgroundJobId),
                    value: backgroundJobId,
                })),
            );
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const setPageAndSize = (page, size) => {
        setPage(page);
        setSize(size);
    };

    useEffect(() => {
        fetchBackgroundJobs(page, size);
    }, [ page, size ]);


    return (
        <Space direction='vertical' size='middle' className='width--full-size'>
            {backgroundJobIdOptions.length > 0 && (
                <>
                    <BackgroundJob
                        key={creationFormReRenderingBoolean}
                        title='Создание нового фонового процесса'
                        backgroundJobIdOptions={backgroundJobIdOptions}
                        creationForm={true}
                        onSubmit={() => {
                            setCreationFormReRenderingBoolean(!creationFormReRenderingBoolean);
                            fetchBackgroundJobs(page, size);
                        }}
                    />

                    <Divider style={{ margin: '12px 0' }} />
                </>
            )}

            <Input.Search
                allowClear
                placeholder='Название фонового процесса...'
                enterButton='Найти'
                loading={isLoading}
                value={searchQuery}
                onChange={(event) => {
                    setSearchQuery(event.target.value);
                }}
                onSearch={() => {
                    setPage(1);
                    fetchBackgroundJobs(page, size, searchQuery);
                }}
            />

            {
                isLoading ? <Skeleton active /> :
                errorMessage ? <Alert message={errorMessage} type='error' showIcon /> :
                totalNumber === '0' ? <Alert message='Фоновые процессы отсутствуют' type='info' showIcon /> : (
                    <>
                        {backgroundJobs.map((backgroundJob) => (
                            <BackgroundJob
                                key={backgroundJob.id}
                                title={capitalizeString(backgroundJob.id)}
                                backgroundJob={backgroundJob}
                                backgroundJobIdOptions={backgroundJobIdOptions}
                                onSubmit={() => {
                                    setSearchQuery('');
                                    fetchBackgroundJobs(page, size);
                                }}
                            />
                        ))}

                        {totalNumber > minPageSize && (
                            <Pagination
                                showSizeChanger
                                total={totalNumber}
                                current={page}
                                pageSize={size}
                                onChange={setPageAndSize}
                            />
                        )}
                    </>
                )
            }
        </Space>
    );
};


export default BackgroundJobsTab;
