import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Skeleton, Typography } from 'antd';

import * as TaskService from '../../core/services/task';
import { useCurrentRoute } from '../../hooks/useCurrentRoute';

import TaskForEmployee from '../../components/cards/TaskForEmployee';
import TitledLayout from '../../components/layouts/TitledLayout';


const TaskLinkPage = ({ taskCompletionId }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ task, setTask ] = useState(null);

    const currentRoute = useCurrentRoute();
    const navigate = useNavigate();

    const fetchTask = async (taskCompletionId) => {
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const fetchedTask = await TaskService.getTaskOfEmployee(taskCompletionId);

            setTask(fetchedTask);
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTask(taskCompletionId);
    }, [ taskCompletionId ]);


    if (isLoading) { return <Skeleton active />; }

    return (
        <TitledLayout title='Задача'>
            {task === null || errorMessage !== null ? (
                <Alert
                    message={errorMessage || `Не удалось найти задачу, поскольку передан некорректный ID '${taskCompletionId}'`}
                    type='error'
                    showIcon
                />
            ): (
                <TaskForEmployee
                    task={task}
                    onUpdate={() => { fetchTask(taskCompletionId); }}
                />
            )}

            <Typography.Link
                onClick={() => {
                    navigate(currentRoute.path);
                }}
                style={{
                    display: 'block',
                    paddingTop: 12,
                    marginLeft: 24,
                }}
            >
                перейти к списку задач
            </Typography.Link>
        </TitledLayout>
    );
};


export default TaskLinkPage;
