import api from '../api';
import { recordsTotalNumberHeader } from '../utilities/constants';


export const getCityListWithWarehouses = async () => {
    const response = await api.get('/city/list/with-warehouses');

    return response.data;
};


export const getCityList = async (
    page,
    size,
    searchQuery = null,
) => {
    const response = await api.get('/city/list', {
        params: {
            page,
            size,
            search_query: searchQuery,
        },
    });

    return [
        response.data,
        response.headers[recordsTotalNumberHeader],
    ];
};


export const createCity = async (name) => {
    const response = await api.post('/city', {
        name,
    });

    return response.data;
};


export const updateCity = async (
    cityId,
    name,
) => {
    let updateData = {};

    if (name !== undefined) { updateData.name = name; }

    const response = await api.patch('/city', updateData, {
        params: {
            id: cityId,
        },
    });

    return response.data;
};


export const deleteCity = async (cityId) => {
    const response = await api.delete('/city', {
        params: {
            id: cityId,
        },
    });

    return response.data;
};
