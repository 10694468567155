import { useState } from 'react';
import { Form, Input, Select, notification } from 'antd';

import * as WarehouseService from '../../core/services/warehouse';
import { makeRequiredRule } from '../../core/utilities/callables';

import CardWithButtonGroup from '../CardWithButtonGroup';
import { filterOption, isFormFieldValueChanged } from '../utilities/callables';


const Warehouse = ({
    title,
    warehouse = null,
    cityOptions,
    creationForm: isCreationForm = false,
    onSubmit,
}) => {
    const [ form ] = Form.useForm();
    const [ isBeingEdited, setIsBeingEdited ] = useState(isCreationForm);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isCitySelectOpen, setCitySelectOpen ] = useState(false);
    const [ notificator, notificationContextHolder ] = notification.useNotification();

    const onCitySelectOpenChange = (isOpen) => {
        setCitySelectOpen(isBeingEdited && isOpen);
    };

    const editWarehouse = () => {
        if (isCreationForm) { return; }

        setIsBeingEdited(true);
    };

    const cancelEditingWarehouse = () => {
        form.resetFields();
        setIsBeingEdited(false);
    };

    const saveWarehouse = async () => {
        const formDataEntries = Object.entries(form.getFieldsValue()).filter(
            ([ fieldName, fieldValue ]) => isFormFieldValueChanged(warehouse, fieldName, fieldValue, isCreationForm),
        );

        if (formDataEntries.length === 0) {
            if (!isCreationForm) {
                setIsBeingEdited(false);                
            }

            return;
        }

        try {
            await form.validateFields();
        } catch {
            return;
        }

        setIsLoading(true);

        const formData = Object.fromEntries(formDataEntries);

        try {
            if (isCreationForm) {
                await WarehouseService.createWarehouse(formData.city_id, formData.name);
            } else {
                await WarehouseService.updateWarehouse(warehouse.id, formData.city_id, formData.name);

                setIsBeingEdited(false);
            }

            onSubmit();
        } catch (error) {
            notificator.error({
                message: `Ошибка ${isCreationForm ? 'создания' : 'обновления'} склада`,
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const deleteWarehouse = async () => {
        setIsLoading(true);

        try {
            await WarehouseService.deleteWarehouse(warehouse.id);

            onSubmit();
        } catch (error) {
            notificator.error({
                message: 'Ошибка удаления склада',
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {notificationContextHolder}

            <CardWithButtonGroup
                title={title}
                isCreationForm={isCreationForm}
                isBeingEdited={isBeingEdited}
                isLoading={isLoading}
                onEdit={editWarehouse}
                onCancelEditing={cancelEditingWarehouse}
                onSave={saveWarehouse}
                onDelete={deleteWarehouse}
            >
                <Form layout='vertical' form={form} initialValues={{ ...warehouse }}>
                    <Form.Item
                        name='name'
                        label='Название'
                        rules={[ makeRequiredRule('Введите название') ]}
                    >
                        <Input readOnly={!isBeingEdited} placeholder='...' />
                    </Form.Item>
                    <Form.Item
                        name='city_id'
                        label='Город'
                        rules={[ makeRequiredRule('Выберите город') ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Select
                            virtual={false}
                            showSearch={isBeingEdited}
                            placeholder='...'
                            optionFilterProp='children'
                            filterOption={filterOption}
                            options={cityOptions}
                            open={isCitySelectOpen}
                            onDropdownVisibleChange={onCitySelectOpenChange}
                        />
                    </Form.Item>
                </Form>
            </CardWithButtonGroup>
        </>
    );
};


export default Warehouse;
