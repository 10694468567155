import { useState } from 'react';
import { Button, Card, Form, Input, Select, Space, Switch } from 'antd';
import { CheckOutlined, CloseOutlined, DeleteOutlined, ExportOutlined } from '@ant-design/icons';

import { makeRequiredRule } from '../../core/utilities/callables';
import { IndicatorDataType } from '../../core/utilities/constants';

import { filterOption } from '../utilities/callables';


const IndicatorFormItem = ({
    name,
    indicator = null,
    isBeingEdited = true,
    isCreationForm = false,
    onDelete,
    onRestore,
    replaceDeleteByRestore = false,
    hideDelete = false,
}) => {
    const [ isDataTypeSelectOpen, setIsDataTypeSelectOpen ] = useState(false);

    const onDataTypeSelectOpenChange = (isOpen) => {
        setIsDataTypeSelectOpen(isBeingEdited && isOpen);
    };

    
    if (!Array.isArray(name)) {
        name = [name];
    }


    return (
        <Card type='inner' size='small' style={{ marginBottom: 12 }}>
            <Space.Compact style={{ display: 'flex', marginBottom: 20 }}>
                <Form.Item
                    name={[ ...name, 'name' ]}
                    label='название'
                    rules={!isCreationForm && [ makeRequiredRule('Введите название') ]}
                    initialValue={indicator?.name}
                    style={{ flex: 1, marginBottom: 0 }}
                >
                    <Input readOnly={!isBeingEdited} placeholder='...' />
                </Form.Item>

                <Form.Item
                    name={[ ...name, 'data_type' ]}
                    label='тип данных'
                    rules={!isCreationForm && [ makeRequiredRule('Выберите тип данных') ]}
                    initialValue={indicator?.data_type}
                    style={{ flex: 1, marginBottom: 0 }}
                >
                    <Select
                        virtual={false}
                        showSearch={isBeingEdited}
                        placeholder='...'
                        optionFilterProp='children'
                        filterOption={filterOption}
                        options={Object.values(IndicatorDataType)}
                        open={isDataTypeSelectOpen}
                        onDropdownVisibleChange={onDataTypeSelectOpenChange}
                    />
                </Form.Item>
            </Space.Compact>

            <Form.Item
                name={[ ...name, 'should_be_exported' ]}
                label='экспортировать значение показателя'
                initialValue={indicator?.should_be_exported ?? true}
                valuePropName='checked'
                style={{ marginBottom: 0 }}
            >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    disabled={!isBeingEdited}
                    style={{ width: 60 }}
                />
            </Form.Item>

            {!hideDelete && isBeingEdited && (
                <div
                    className='width--full-size'
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    {replaceDeleteByRestore ? (
                        <Button icon={<ExportOutlined />} onClick={onRestore}>
                            Восстановить
                        </Button>
                    ) : (
                        <Button icon={<DeleteOutlined />} danger onClick={onDelete}>
                            Удалить
                        </Button>
                    )}
                </div>
            )}
        </Card>
    );
};


export default IndicatorFormItem;
