import { useEffect, useState } from 'react';
import { Alert, Skeleton, Timeline } from 'antd';
import dayjs from 'dayjs';

import * as TaskService from '../core/services/task';


const TaskCompletionsTimeline = ({
    isExpanded,
    taskId,
}) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ taskCompletions, setTaskCompletions ] = useState([]);

    const fetchTaskCompletions = async (taskId) => {
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const fetchedTaskCompletions = await TaskService.getTaskCompletionList(taskId);

            setTaskCompletions(fetchedTaskCompletions.map((taskCompletionData) => ({
                children: (
                    <>
                        <p>{taskCompletionData.employee || `${taskCompletionData.warehouse} (${taskCompletionData.warehouse_city})`}</p>
                        <p>{dayjs(taskCompletionData.completed_at).format('DD.MM.YYYY в HH:mm:ss')}</p>
                    </>
                )
            })));
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isExpanded) {
            fetchTaskCompletions(taskId);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isExpanded ]);


    if (isLoading) { return <Skeleton active title={false} />; }
    if (errorMessage) { return <Alert message={errorMessage} type='error' showIcon />; }

    if (taskCompletions.length === 0) { return <Alert message='Задача ещё не завершена ни одним из исполнителей.' showIcon />; }

    return (
        <Timeline items={taskCompletions} />
    );
};


export default TaskCompletionsTimeline;
